const frJSON = {
  AUTH_SIGN_UP_CARD_TITLE: "S'inscrire",
  AUTH_SIGN_UP_CARD_SUB_TITLE:
    'Remplissez les champs ci-dessous pour créer un compte.',
  AUTH_SIGN_UP_CARD_GOOGLE_BUTTON: 'Inscrivez-vous avec Google',
  AUTH_SIGN_UP_CARD_OR_SEPARATOR: 'ou',
  AUTH_SIGN_UP_CARD_NAME_FIELD_LABEL: 'Nom',
  AUTH_SIGN_UP_CARD_NAME_FIELD_ERROR:
    'Il est requis de compléter le champ correspondant au nom',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_LABEL: 'E-mail',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_REQUIRED:
    'Le champ email est obligatoire',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_INVALID:
    "L'e-mail fourni doit être une adresse e-mail valide",
  AUTH_SIGN_UP_CARD_PASS_FIELD_LABEL: 'Mot de passe',
  AUTH_SIGN_UP_CARD_PASS_FIELD_ERROR: 'Le champ mot de passe est obligatoire',
  AUTH_SIGN_UP_CARD_TERMS_AGREE_STATEMENT: "J'accepte le",
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS: 'termes et conditions',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS_ERROR:
    'Vous devez accepter nos termes et conditions',
  AUTH_SIGN_UP_CARD_BUTTON: "S'inscrire",
  AUTH_SIGN_UP_CARD_HAVE_ACCOUNT: 'Vous avez déjà un compte?',
  AUTH_SIGN_UP_CARD_LOGIN_LINK: 'Se connecter ici',
  AUTH_SIGN_UP_SIDEBAR_TITLE: "Créer des rapports d'inspection numériques",
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_1: 'Tableau de bord de gestion des inspections',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_2: "Application d'inspection facile à utiliser",
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_3:
    "Rapports d'inspection numériques axés sur le mobile",
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_4: 'Essayez 10 rapports gratuits par mois',
  AUTH_LOGIN_CARD_EMAIL_FIELD_LABEL: 'E-mail',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_REQUIRED: 'Le champ email est obligatoire',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_INVALID:
    "L'e-mail fourni doit être une adresse e-mail valide",
  AUTH_LOGIN_CARD_PASS_FIELD_LABEL: 'Mot de passe',
  AUTH_LOGIN_CARD_PASS_FIELD_ERROR: 'Le champ mot de passe est obligatoire',
  AUTH_LOGIN_CARD_GOOGLE_BUTTON: 'Connectez-vous avec Google',
  AUTH_LOGIN_CARD_BUTTON: 'Se connecter',
  AUTH_LOGIN_CARD_FORGOT_PASSWORD: 'Mot de passe oublié?',
  AUTH_LOGIN_CARD_RESET_PASSWORD_LINK: 'réinitialiser le mot de passe',
  AUTH_LOGIN_CARD_NO_ACCOUNT: "Vous n'avez pas de compte ?",
  AUTH_LOGIN_CARD_REGISTER_LINK: 'Registre',
  AUTH_LOGIN_CARD_ERROR_INVALID_CREDENTIALS: 'email ou mot de passe incorrect',
  USERS_LIST_PAGE_HEADER_TITLE: 'Utilisateurs',
  USERS_LIST_PAGE_HEADER_CAPTION: 'Gérez vos utilisateurs',
  USERS_LIST_EMPTY_MESSAGE: 'Aucun utilisateur',
  USERS_LIST_PAGE_HEADER_CREATE_BUTTON: 'Créer un nouvel utilisateur',
  USERS_LIST_DELETE_USER_CONFIRMATION_MESSAGE:
    'Voulez-vous vraiment supprimer cet utilisateur ?',
  USERS_LIST_DELETE_USER_BUTTON_TOOLTIP: 'Supprimer',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_TITLE: 'utilisateur créé',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_PASSWORD:
    "L'utilisateur a été créé avec le mot de passe :",
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL:
    "Un e-mail d'invitation a également été envoyé à l'utilisateur à l'adresse",
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL_EXTENDED:
    ', ils peuvent utiliser le lien dans cet e-mail pour définir leur propre mot de passe.',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_CAPTION:
    'Utilisez ce mot de passe pour vous connecter',
  USERS_LIST_CREATE_USER_DIALOG_TITLE: 'Créer un nouvel utilisateur',
  USERS_LIST_CREATE_USER_DIALOG_CAPTION: 'Ajouter un nouvel utilisateur',
  USERS_LIST_CREATE_USER_DIALOG_CREATE_BUTTON: 'Créer un utilisateur',
  REPORT_DETAILS_HEADER_TITLE: 'Détails du rapport',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'Télécharger le PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Télécharger XLSX',
  REPORT_DETAILS_NO_ANSWER: 'Pas de réponse',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Responsabilité',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'Cette section montre qui a réalisé le rapport',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Vérification',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Toutes les données vérifiées avec Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unité',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspection',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_TITLE: 'Es-tu sûr?',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_SUBTITLE:
    'Cela supprimera également tous les éléments de cette catégorie.',
  COPIED: 'Copié avec succès...',
  NO_ACTIVITY_MESSAGE:
    "Les détails de l'activité ne sont pas encore disponibles...",
  COMMENT: 'COMMENTAIRE',
  configurations: 'configurations',
  new_configuration: 'nouvelle configuration',
  Key: 'Clé',
  Title: 'Titre',
  Actions: 'Actions',
  Clients: 'Clients',
  Value: 'Valeur',
  Edit: 'Modifier',
  Cancel: 'Annuler',
  Save: 'Sauvegarder',
  Saved: 'Enregistré',
  OPTIONS: 'Choix',
  FORGET_PASSWORD: 'Oublier le mot de passe',
  UPDATE_PASSWORD_TITLE: 'Mettre à jour le mot de passe',
  CONFIRM_PASSWORD_VALIDATION:
    'Veuillez saisir le même mot de passe dans les champs Mot de passe et Confirmer le mot de passe.',
  PASSWORD_VALIDATION: 'Le champ mot de passe est obligatoire',
  TITLE_VALIDATION: 'Le champ titre est obligatoire',
  PASSWORD_UPDATED:
    'Le mot de passe a été mis à jour avec succès, veuillez essayer de vous connecter',
  ERROR: "Quelque chose s'est mal passé",
  PASSWORD: 'Mot de passe',
  CONFIRM_PASSWORD: 'Confirmez le mot de passe',
  UPDATE_PASSWORD: 'Mettre à jour le mot de passe',
  UPDATE_USER: "Mettre à jour l'utilisateur",
  INVALID_EMAIL: 'Email invalide',
  UPDATE_PASSWORD_TOGGEL: 'Mettre à jour le mot de passe',
  NAME_VALIDATION: 'Il est requis de compléter le champ correspondant au nom',
  EMAIL_VALIDATION: 'Le champ email est obligatoire',
  MESSAGE_VALIDATION: 'Le champ message est obligatoire',
  SHOULD_VALID_EMAIL: "L'e-mail fourni doit être une adresse e-mail valide",
  IMAGE_VALIDATION: 'Le champ image est obligatoire',
  NAME_PLACEHOLDER: 'Nom...',
  NAME_LABEL: 'Nom',
  EMAIL_PLACEHOLDER: 'E-mail...',
  EMAIL_LABEL: 'E-mail',
  MESSAGE_LABEL: 'Message',
  CLIENT_IMAGE_LABEL: 'Image',
  PASSWORD_PLACEHOLDER: 'Mot de passe...',
  PASSWORD_LABEL: 'Mot de passe',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirmez le mot de passe...',
  CONFIRM_PASSWORD_LABEL: 'Confirmez le mot de passe',
  SAVE_USER: "Enregistrer l'utilisateur",
  UPDATE_PROFILE: 'Mettre à jour le profil',
  FLAGGED: 'Marqué',
  REPORT_DETAILS: 'Détails du rapport',
  REMOVE_FLAG: 'Supprimer le drapeau',
  ADD_FLAG: 'Ajouter un drapeau',
  CLIENT_REPORTS: 'Rapports clients',
  CLIENTS: 'Clients',
  REPORTS: 'Rapports',
  CHECKS: 'Vérifications',
  UNITS: 'Unités',
  DELETE: 'Supprimer',
  DELETED: 'Supprimé avec succès',
  UNIT_DELETE_ALERT_TITLE: 'Etes-vous sûr de vouloir supprimer cette unité ?',
  NO: 'Non',
  YES: 'Oui',
  NOT_SURE: 'Pas certain',
  FORGET_PASSWORD_Q: 'Mot de passe oublié?',
  RESET_PASSWORD: 'réinitialiser le mot de passe',
  RESET_PASSWORD_TITLE: 'Récupérer mot de passe',
  RESET_PASSWORD_SUB_TITLE:
    'Entrez votre e-mail pour réinitialiser votre mot de passe',
  UPDATE_PASSWORD_SUB_TITLE:
    'Entrez votre mot de passe pour mettre à jour votre mot de passe',
  SEND_NEW_PASSWORD: 'Envoyez moi un nouveau mot de passe',
  TRY_SIGN: 'Voulez-vous réessayer de vous connecter ?',
  CLICK_HERE: 'Cliquez ici',
  PLATFORM_TEMPLATE_INFO:
    "Les modèles de plateforme ne peuvent pas être modifiés tant qu'ils ne sont pas connectés à une unité.",
  RESET_PASSWORD_INSTRUCTIONS:
    'Les instructions de réinitialisation du mot de passe ont été envoyées à votre adresse e-mail',
  CHECK_EMAIL_INSTRUCTIONS:
    "Vérifiez votre courrier électronique pour plus d'instructions",
  CONTINUE_LOGIN: 'Continuer à vous connecter',
  TITLE: 'Titre',
  REFERENCES: 'Inspections',
  REFERENCE: 'Inspection',
  GENERATE_REFERENCE: 'Créer une nouvelle inspection',
  LINK: 'Lien',
  NO_REPORTS: "Aucun rapport créé pour l'instant",
  FREE_TRIAL: "Commencez votre essai gratuit aujourd'hui",
  EMAIL_VERIFICATION: 'Vérifiez votre e-mail',
  SEND_EMAIL_TO: 'Nous avons envoyé un email à',
  EMAIL_VERIFICATION_CONTINUE:
    'Pour continuer, veuillez vérifier votre courrier électronique et cliquer sur le lien pour vérifier votre compte.',
  NOT_RECEIVE_EMAIL: "Vous n'avez pas reçu l'e-mail ?",
  RESEND_EMAIL: "Renvoyer l'email",
  EMAIL_VERIFICATION_RESEND_ERROR: "Échec de l'envoi de l'e-mail",
  TERMS_VALIDATION: 'Vous devez accepter nos termes et conditions',
  REGISTRATION_LAST_STEP: 'Encore un pas et le tour est joué !',
  KNOW_MORE_INFO:
    "S'il vous plaît laissez-nous savoir le nom de votre entreprise",
  COMPANY_VALIDATION: 'Le champ entreprise est obligatoire',
  COMPANY_LABEL: "Nom de l'entreprise",
  COMPANY: 'Entreprise',
  SIGN_UP_HERE: "S'inscrire",
  GETTING_START: 'Commencer',
  THANK_YOU: 'Merci!',
  ON_BOARD: 'Nous sommes ravis de vous accueillir à bord.',
  UPDATE: 'Mise à jour',
  SAVED: 'Enregistré avec succès',
  SENT: 'Bien envoyé',
  UPDATE_COMPANY_HEADER: "Détails de l'entreprise",
  UPDATE_COMPANY_INFO:
    "Vous devez définir le nom de l'entreprise pour continuer",
  SEND_INSTRUCTIONS_EMAIL: "Envoyer un e-mail d'instructions",
  TOKEN_INSTRUCTION_EMAIL_TITLE:
    "Envoyer un e-mail d'instructions pour le rapport",
  INSTRUCTION_EMAIL: 'E-mail',
  RESET_PASSWORD_SUCCESS:
    "Merci, si l'adresse e-mail que vous avez saisie est correcte, vous recevrez sous peu un e-mail contenant des instructions sur la façon de réinitialiser votre mot de passe.",
  UPDATE_CLIENT: "Détails de l'entreprise",
  UPDATE_CLIENT_PAGE_TITLE:
    'Utilisez cette page pour mettre à jour les détails de votre entreprise.',
  UPDATE_CLIENT_CARD_TITLE: "Détails de l'entreprise",
  COMPANY_NAME_PLACEHOLDER: "Mettre à jour le nom de l'entreprise",
  COMPANY_NAME_LABEL: "Nom de l'entreprise",
  SAVE_CLIENT: 'Enregistrer le client',
  'Yes/No': 'Oui Non',
  MEDIA: 'Médias',
  TEXT: 'Texte',
  NUMBER: 'Nombre',
  RADIO_BUTTON: 'Choix multiple',
  CHECK_BOX: 'Cases à cocher',
  SELECT_OPTIONS_HOLDER: 'entrées séparées avec touche retour',
  SELECT_OPTIONS: 'Choix',
  SELECT_OPTIONS_HELPER:
    'Veuillez lister les options dans le champ de texte ; utilisez Entrée pour séparer les options',
  REGISTER_HTML_TITLE: "Vérifiez d'abord - Inscrivez-vous",
  FILTER: 'Filtre',
  FLAGGED_all: 'Afficher tout',
  FLAGGED_true: 'Rapports signalés uniquement',
  MANAGE_API_KEY_SUBHEADER: 'Gérez vos clés API',
  API_KEY_MODAL_HEADER: 'Générer une clé API',
  API_KEY_MODAL_SUBHEADER: 'Veuillez saisir un nom pour identifier cette clé',
  CREATED_API_KEY_MODAL_HEADER: 'Clé créée',
  CREATED_API_KEY_MODAL_SUBHEADER:
    "Veuillez le conserver dans un endroit sûr car il ne pourra pas être récupéré. Consultez la documentation du développeur pour savoir comment l'utiliser !",
  NAME: 'Nom',
  DOWNLOAD_XLSX: 'Télécharger Xlsx',
  SUBSCRIBE_TITLE: 'Choisissez votre forfait',
  SUBSCRIBE_BODY:
    'Une tarification simple et transparente qui évolue avec vous. Essayez n’importe quel forfait gratuitement pendant 30 jours.',
  SUBSCRIBE_BODY2: 'Tarif annuel (économisez 15%)',
  SUBSCRIBE: "S'abonner",
  PAYMENT_SUCCESS_BUTTON: 'Commencer',
  TRY_AGAIN: 'Essayer à nouveau',
  PAYMENT_SUCCESS_TITLE: 'Merci',
  PAYMENT_SUCCESS_BODY:
    'Vos informations ont été enregistrées avec succès. Nous sommes ravis de vous accueillir à bord !',
  PAYMENT_FAILURE_TITLE: "Quelque chose s'est mal passé",
  PAYMENT_FAILURE_BODY:
    'Votre paiement peut avoir échoué à cause du solde de votre carte ou pour autre chose, veuillez contacter le service client pour plus de précisions.',
  SUBSCRIPTION_TITLE: 'Gérer les abonnements',
  SUBSCRIPTION_DESCRIPTION: 'GÉREZ VOTRE ABONNEMENT',
  SUBSCRIPTION_CARD_HEADER: "Information d'abonnement",
  SUBSCRIPTION_CARD_SUB_HEADER:
    'Gérer les détails liés à vos adresses e-mail associées',
  SUBSCRIPTION_PLAN: 'Plan',
  SUBSCRIPTION_CARD: 'Carte de crédit',
  SUBSCRIPTION_BILLED_WITH: 'Facturé avec Mastercard ••••',
  MANAGE_SUBSCRIPTION: "Gérer l'abonnement",
  SUBSCRIPTION_ACTIVE: 'Actif',
  SUBSCRIPTION_IN_ACTIVE: 'Inactif',
  SUBSCRIPTION_END: 'DATE DE FIN',
  PRICING_PLANS: 'Plans tarifaires',
  CURRENCY: '€',
  MONTH: 'mois',
  YEAR: 'le',
  ENTERPRISE_TITLE: 'Entrer en contact',
  ENTERPRISE_NAME: 'Platine',
  ENTERPRISE_DESCRIPTION: 'Créé pour les clients entreprises',
  ENTERPRISE_FEATURE1: 'Unités illimitées',
  ENTERPRISE_FEATURE2: 'Authentification unique',
  ENTERPRISE_FEATURE3: 'SLA haute disponibilité',
  ENTERPRISE_FEATURE4: 'Intégrations sur mesure',
  ENTERPRISE_FEATURE5: "Accompagnement et formation à l'intégration",
  ENTERPRISE_FEATURE6: 'Toutes les fonctionnalités Gold',
  EDIT_ITEM: 'Mise à jour',
  UPDATE_CHECKLIST_ITEM: 'Mettre à jour les éléments de la liste de contrôle',
  EDIT: 'Modifier',
  N0_CLIENTS_ACTIVITIES: 'Aucune activité client',
  CREATION_DATE: 'Date de création',
  ACTIVITY_ACTION: 'Action',
  ACTIVITY_MODULE: 'Module',
  CLIENT_NAME: 'Nom',
  CLIENT_LABEL: 'Client',
  CLIENT_ACTIVITY_SEARCH_PLACEHOLDER: 'Sélectionnez un client',
  CLIENT_ACTIVITY_TITLE: 'Activités clients',
  MULTI_MEDIA: 'Multimédia',
  CONDITION: 'Condition',
  UPLOAD: 'Sélectionnez une image à télécharger',
  BRONZE: 'Bronze',
  GOLD: 'Or',
  ENTERPRISE: 'Entreprise',
  NONE: 'Aucun',
  HISTORY: 'Histoire',
  CHECKLIST_ITEM_DRILL_DOWN_DESCRIPTION:
    'Vous trouverez ci-dessous l’historique de toutes les inspections de cette unité. Cliquez sur chaque date pour charger le rapport correspondant.',
  REPORTS_TO_DATE: 'Rapports à ce jour',
  FLAGGED_REPORTS: 'Rapports signalés',
  ACTIVE_UNITS: 'Unités actives',
  RECENT_REPORTS: 'Rapports récents',
  RECENT_UNITS: 'Unités récemment ajoutées',
  INSPECTOR: 'Inspecteur',
  DATE: 'Date',
  REPORTS_COUNT: 'Rapports',
  UNIT: 'Unité',
  VIEW_ALL: 'Voir tout',
  ISSUE_PRIORITY: 'Priorité du problème',
  ISSUE_TITLE: 'Priorité du problème',
  ISSUE_DESCRIPTION: 'Description',
  ISSUE_ID: 'IDENTIFIANT',
  ISSUE_REPORTED: 'Signalé',
  ISSUES: 'Marqué',
  ISSUE_GALLERY: 'Galerie',
  ISSUE_DETAILS: 'Détails du problème',
  SHARING_REPORT: 'Partager',
  LOGGED_IN_FIRST: 'Vous devez être connecté',
  WELCOME_MESSAGE: 'Accueillir,',
  YOUR_STATISTICS_MESSAGE: 'Vos statistiques du jour,',
  ISSUES_HEADER: 'Marqué',
  ISSUES_SUBHEADER: 'Gérer les problèmes détectés lors de l’inspection',
  ISSUES_PRIORITY: 'PRIORITÉ',
  UPPERCASE_TITLE: 'TITRE',
  STATUS: 'STATUT',
  UPPERCASE_UNIT: 'UNITÉ',
  DESCRIPTION: 'DESCRIPTION',
  IMAGES: 'IMAGES',
  REPORTED: 'SIGNALÉ',
  DEACTIVATED: 'Désactivé',
  DEACTIVATE: 'Désactiver',
  CO_SIGN: 'Co-signer',
  CO_SIGN_HERE: 'Co-signez ici',
  SIGN_MODAL_TITLE: 'Cosigner le rapport',
  SIGN_MODAL_DESCRIPTION:
    "Utilisez cette fenêtre de dialogue pour cosigner le rapport d'inspection.",
  NAME_HOLDER: 'Ajouter un nom',
  NAME_REQUIRED: 'Il est requis de compléter le champ correspondant au nom',
  SIGNATURE_IMAGE_URL: 'Signature',
  SIGNATURE_REQUIRED: 'Le champ signature est obligatoire',
  SIGN_BUTTON: 'Soumettre',
  UPDATE_PASSWORD_INFO: 'Voulez-vous mettre à jour votre mot de passe ?',
  UPDATE_PASSWORD_LINK: 'Mettre à jour le mot de passe',
  UPDATE_USER_PASSWORD: 'Changer le mot de passe',
  UPDATE_USER_PASSWORD_CARD_TITLE: "informations de l'utilisateur",
  OLD_PASSWORD_LABEL: 'Mot de passe actuel',
  SAVE_USER_PASSWORD: 'Sauvegarder',
  OLD_PASSWORD_PLACEHOLDER: 'Mot de passe actuel...',
  COPY_REPORT_TOKEN: 'Copier le jeton de rapport',
  QR_CODE: 'QR Code',
  QR_CODE_MODAL_TITLE: "Lien d'inspection QR",
  DOWNLOAD: 'Télécharger',
  DOWNLOADED: 'Téléchargé avec succès',
  DEVELOPMENT: 'Développement',
  UNIT_FORM_DESCRIPTION_PLACEHOLDER: 'Entrez une description ici.',
  UNIT_FORM_TITLE_PLACEHOLDER: 'Entrez un titre ici.',
  UNIT_FORM_DESCRIPTION_CAPTION:
    "Ces informations seront vues par l'utilisateur final lorsqu'il démarrera le rapport, alors assurez-vous que cette description l'aide à créer le rapport de la manière la mieux adaptée à votre entreprise.",
  CREATE_USER_NAME_PLACEHOLDER: 'Entrez le nom ici',
  CREATE_USER_EMAIL_PLACEHOLDER: "Entrez l'e-mail ici",
  SAVE: 'Sauvegarder',
  CREATE: 'Créer',
  UPLOAD_CLIENT_LOGO: 'Logo',
  COPY: 'Copie',
  ROLE: 'Rôle',
  ROLE_LABEL: "Sélectionnez le rôle de l'utilisateur",
  ROLE_client: 'Administrateur des clients',
  ROLE_client_manager: 'Gestionnaire de clientèle',
  ROLE_client_contributor: 'Contributeur client',
  USERS_LIST_EDIT_USER_BUTTON_TOOLTIP: "Mettre à jour l'utilisateur",
  USERS_LIST_UPDATE_USER_DIALOG_TITLE:
    'Mettre à jour les informations utilisateur',
  USERS_LIST_UPDATE_USER_DIALOG_CAPTION: "Mettre à jour l'utilisateur",
  USERS_LIST_UPDATE_USER_DIALOG_CREATE_BUTTON: "Mettre à jour l'utilisateur",
  WEBHOOK_SCREEN_LIST_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_LIST_CAPTION: 'Gérez vos webhooks ici',
  WEBHOOK_SCREEN_LIST_CREATE_BUTTON: 'Ajouter un webhook',
  REPORT_CREATED: 'Rapport',
  ISSUE_CREATED: 'Problème',
  COUNTER_SIGNATURE_CREATED: 'Contre-signature',
  TYPE: 'TAPER',
  URL: 'URL',
  MODIFIED: 'MODIFIÉ',
  NO_WEBHOOKS_ADDED: "Aucun webhook ajouté pour l'instant.",
  ACTION: 'ACTION',
  EDIT_WEBHOOK: 'Modifier',
  WEBHOOK_DETAILS: 'Détails',
  WEBHOOK_SCREEN_SAVE_TITLE: 'Ajouter un webhook',
  WEBHOOK_SCREEN_SAVE_CAPTION:
    "Sélectionnez le type d'événement dont vous souhaitez être informé, puis ajoutez l'URL de votre webhook.",
  HOOK_TYPE_REQUIRED: 'Le champ Type est obligatoire',
  HOOK_URL_REQUIRED: 'Le champ URL est obligatoire',
  ADD_WEBHOOK: 'Ajouter un webhook',
  WEBHOOK_TYPE_LABEL: 'Taper',
  WEBHOOK_TYPE_PLACEHOLDER: 'Taper',
  WEBHOOK_URL_PLACEHOLDER: 'URL',
  WEBHOOK_URL_LABEL: 'URL',
  WEBHOOK_SCREEN_DETAILS_TITLE: 'Webhooks',
  WEBHOOK_SCREEN_DETAILS_CAPTION: 'Organisation / Webhooks',
  HOOK_DATE: 'DATE',
  WEBHOOK_RETRY: 'Recommencez',
  SUCCESS: 'Succès',
  FAILED: 'Échoué',
  REQUEST_DETAIL_MODAL_HEADER: 'Détails de la demande',
  DETAILS: 'Détails',
  RESPONSE: 'RÉPONSE',
  BODY: 'Corps',
  ACTIONS: 'Actions',
  INVALID_MAGIC_LINK: 'Le lien magique est peut-être invalide ou expiré',
  URL_VALIDATION: 'Veuillez saisir une URL valide',
  UNIT_FORM_REDIRECT_URL_PLACEHOLDER:
    'Entrez ici une URL de redirection d’inspection.',
  UNIT_FORM_REDIRECT_URL_LABEL:
    'Entrez ici une URL de redirection d’inspection.',
  ID_NAME: "Nom d'identification",
  CREATED_AT: 'Créé',
  OPEN: 'Ouvrir',
  NEW_CHECKLIST_TITLE: 'Nouvelle liste de contrôle',
  UNTITLED_CHECKLIST: 'Liste de contrôle sans titre',
  UNTITLED_CHECKLIST_FROM_TEMPLATE:
    'Liste de contrôle sans titre (à partir du modèle)',
  CREATE_CHECKLIST: 'Créer une nouvelle liste de contrôle',
  UPDATE_CHECKLIST: 'Liste de contrôle de mise à jour',
  SAVE_CHECKLIST_SUBTITLE:
    'Enregistrer le formulaire de progression de la liste de contrôle',
  CHECKLISTS_TITLE: 'Listes de contrôle',
  MANAGE_TEMPLATE: 'Gérer',
  COPY_INSPECTION: 'Copier le lien',
  DUPLICATE: 'Dupliquer',
  DUPLICATED: 'Dupliqué avec succès',
  NEW_UNIT_TITLE: 'Nouvelle unité',
  UPDATE_UNIT: "Mettre à jour l'unité",
  CREATE_UNIT: 'Créer une nouvelle unité',
  CREATE_UNIT_SUBTITLE:
    'Les unités sont les cibles de chaque inspection, comme les actifs ou les emplacements',
  SAVE_UNIT_SUBTITLE: "Enregistrer l'unité",
  NEW_BLANK_TEMPLATE: 'Nouveau modèle vierge',
  TEMPLATE_PAGE_TITLE: 'Modèles',
  PLATFORM_TEMPLATE_PAGE_TITLE: 'Modèles de plateforme',
  CLIENT_TEMPLATE_PAGE_TITLE: 'Galerie de modèles',
  UPDATE_TEMPLATE: 'Modèle de mise à jour',
  SAVE_TEMPLATE_SUBTITLE: "Changer le nom et l'image du modèle",
  CREATE_TEMPLATE: 'Créer un modèle',
  SELECT_TEMPLATE: 'Sélectionnez un modèle',
  TEMPLATE_BUILDER: 'Générateur de modèles',
  MANAGE_CHECKLIST_ITEMS: 'Modifier les éléments de la liste de contrôle',
  CREATE_BLANK: 'Créer un blanc',
  CHECKLIST_DELETE_ALERT_TITLE:
    'Êtes-vous sûr de vouloir supprimer cette liste de contrôle ?',
  CHECKLIST: 'Liste de contrôle',
  CREATE_CATEGORY: 'Créer une catégorie',
  UPDATE_CATEGORY: 'Mettre à jour une catégorie',
  PEOPLE: 'Personnes',
  SELECT: 'Sélectionner....',
  LANGUAGE_pt: 'Portugais',
  LANGUAGE_en: 'Anglais',
  LANGUAGE_fr: 'Français',
  LANGUAGE_de: 'Allemand',
  LANGUAGE_zh: 'Chinois',
  LANGUAGE_es: 'Espagnol',
  LANGUAGE_DEFAULT: 'Défaut',
  Dashboard: 'Tableau de bord',
  Issues: 'Problèmes',
  Organisation: 'Entreprise',
  USERS: 'Utilisateurs',
  Developer: 'Développeur',
  Webhooks: 'Crochets Web',
  Subscription: 'Abonnement',
  MANAGE_UNITS: 'Gérez vos unités',
  ID: 'IDENTIFIANT',
  LAST_REPORT_DATE: 'Dernier rapport',
  DELETE_WARNING: 'Vous ne pourrez pas revenir en arrière après la suppression',
  DELETE_CLIENT_WARNING: 'Voulez-vous vraiment supprimer ce client',
  CREATE_CLIENT: 'Créer un nouveau client',
  CREATE_CLIENT_DESCRIPTION:
    'Utilisez cette fenêtre de dialogue pour ajouter un nouveau client',
  EMAIL: 'E-mail',
  IMAGE: 'Image',
  INVALID_FILE_TYPE: 'Vous ne pouvez pas télécharger ces types de fichiers',
  CLIENT_PASSWORD_INFO: 'Utilisez ce mot de passe pour vous connecter',
  CREATE_CLIENT_BUTTON: 'Créer un client',
  SIGN_OUT: 'se déconnecter',
  DEVELOPER_DOCUMENTATION: 'Documentation du développeur',
  OF: 'de',
  PAGINATION_INFO: 'Lignes par page',
  CANCEL: 'Annuler',
  Client: 'Assigné à',
  Client_here: 'Non assigné',
  New_Category: 'Nouvelle catégorie',
  EDIT_CHECKLIST_ITEM_Title: 'Question',
  CREATE_INSTRUCTION: 'Ajouter des instructions',
  UPDATE_INSTRUCTION: 'Modifier les instructions',
  SAVE_INSTRUCTION_SUBTITLE:
    'L image et la description de l instruction seront visibles à côté de la question pour guider l utilisateur sur la manière de fournir une réponse',
  ADD_ITEM: '+ Ajouter',
  TYPE_OF_RESPONSE: 'Type de réponse',
  Image: 'Image',
  EDIT_Instructions: 'Modifier les instructions',
  ASSIGN_OWNER: 'Assigner un propriétaire',
  ASSIGN_OWNER_TO: 'Attribuer un propriétaire à',
  REMOVE_OWNER: 'Supprimer le propriétaire',
  OWNER_REMOVED: 'Propriétaire supprimé',
  ASSIGNED_OWNER: 'Propriétaire assigné',
  SHARE_CHECK: 'Partager la vérification',
  SHARE_CHECK_DESCRIPTION:
    'Utilisez le formulaire ci-dessous pour la partager avec le vérificateur.',
  TEMPLATE_BUILDER_BREADCRUMB: 'Modèles',
  SEND_VIA: 'Envoyer via',
  To: 'À',
  Subject: 'Sujet',
  EMAIL_TOKEN_DESCRIPTION: 'Description',
  Share_Inspection: 'Partager l inspection',
  Open_Modal: 'Nouvelle vérification',
  CHECKFIRST: 'Checkfirst',
  Select_unit: 'Sélectionner une unité',
  SELECT_CHECKLIST: 'Sélectionner une liste de vérification',
  Checklists: 'Listes de vérification',
  Templates: 'Modèles',
  Email: 'Email',
  Add: 'Ajouter',
  Back: 'Retour',
  Owner: 'Propriétaire',
  Description: 'Description',
  Created: 'Créé',
  UPGRADE: 'Mettre à niveau',
  READY_FOR_AN_UPGRADE: 'Prêt pour une mise à niveau?',
  UPGRADE_ACCOUNT_TO_ACCESS_THEM:
    'Ces rapports sont verrouillés en raison des limites dutilisation de votre plan. Veuillez' +
    '\n' +
    'mettre à niveau votre compte pour y accéder.',
  ADD_LOGIC: 'Ajouter de la logique',
  EDIT_CHECKLIST_ITEM: 'Instructions',
  VERIFY_WITH_AI: "Vérifier avec l'IA",
  ADD_INSTRUCTIONS: 'Ajouter des instructions',
  ADD_IMAGE: 'Ajouter une image',
  CHECKLIST_BUILDER: {
    TITLE_PLACEHOLDER: 'Titre',
    DESCRIPTION_PLACEHOLDER: 'Ajouter une description (facultatif)',
    ADD_CRITERIA_BUTTON_LABEL: 'Ajouter des critères',
    ADD_SECTION_BUTTON_LABEL: 'Ajouter une section',
    SAVED_MESSAGE: 'Enregistré avec succès',
    BACK_BUTTON: 'Retour',
    NEW_CHECK_BUTTON: 'Nouvelle vérification',
    PREVIEW_BUTTON: 'Mode Aperçu',
    USE_TEMPLATE_BUTTON: 'Utiliser le modèle',
    FLOATING_TOOLBAR: {
      DUPLICATE: 'Dupliquer la section',
      DELETE: 'Supprimer la section',
      ADD_CRITERIA: 'Ajouter un critère',
      ADD_SECTION: 'Ajouter une section'
    },
    SECTION: {
      TITLE_PLACEHOLDER: 'Écrivez le titre de la section ici',
      DELETE_CONFIRMATION_MESSAGE:
        'Cela supprimera également tous les éléments de cette section.',
      DELETE_TOOLTIP: 'Supprimer',
      ERRORS: {
        REQUIRED: 'Le champ titre est obligatoire.',
        DUPLICATE:
          "Le titre ne doit pas être l'une des valeurs suivantes : {{sections}}"
      }
    },
    CRITERIA: {
      TITLE_PLACEHOLDER: 'Ajouter des critères',
      TYPE_PLACEHOLDER: 'Type de réponse',
      DELETE_CONFIRMATION_MESSAGE:
        'Êtes-vous sûr de vouloir supprimer ce critère ?',
      REQUIRED_LABEL: 'Requis',
      CHANGE_TYPE_CONFIRMATION_MESSAGE:
        'Ce critère contient des critères logiques et imbriqués. Celui-ci sera perdu si vous modifiez le type de critère. Souhaitez-vous modifier le type de critère ?',
      ERRORS: {
        REQUIRED: 'Le champ titre est obligatoire.',
        MIN: 'Veuillez saisir au moins 2 options.',
        MAX: 'Veuillez saisir moins de 100 options.'
      }
    },
    LOGIC: {
      DAMAGED: 'Endommagé',
      POOR: 'Pauvre',
      FAIR: 'Équitable',
      GOOD: 'Bien',
      GREAT: 'Super',
      IF: 'Si la réponse',
      IF_IMAGE: 'Si image',
      THEN: 'Alors',
      YES: 'Oui',
      NO: 'Non',
      NA: 'N/D',
      IS: 'Est',
      IS_NOT: "N'est pas",
      IS_EMPTY: 'Est vide',
      IS_NOT_EMPTY: "N'est pas vide",
      ADD_CRITERIA: 'Afficher les critères',
      FLAG_REPORT: 'Signaler le rapport',
      RAISE_ISSUE: 'Soulever un problème',
      EQUALS: 'Équivaut à (=)',
      DOES_NOT_EQUAL: "N'est pas égal (≠)",
      GREATER_THAN: 'Supérieur à (>)',
      LESS_THAN: 'Inférieur à (<)',
      GREATER_THAN_OR_EQUAL_TO: 'Supérieur ou égal à (≥)',
      LESS_THAN_OR_EQUAL_TO: 'Inférieur ou égal à (≤)',
      BETWEEN: 'Entre',
      NOT_BETWEEN: 'Pas entre',
      CONTAINS: 'Contient',
      DOES_NOT_CONTAIN: 'Ne contient pas',
      STARTS_WITH: 'Commence avec',
      ACTION_PLACEHOLDER: "Sélectionnez l'action",
      YES_NO_PLACEHOLDER: 'Sélectionnez plusieurs valeurs',
      DELETE_CONFIRMATION_MESSAGE:
        'Etes-vous sûr de vouloir supprimer cette logique ?'
    },
    INSTRUCTIONS: {
      PLACEHOLDER: 'Texte...',
      LABEL: 'Instructions',
      IMAGE_LABEL: 'Image'
    }
  },
  SaveBasicInformation: {
    ERRORS: {
      TITLE_REQUIRED: "Le champ de nom de l'appareil est requis.",
      CLIENT_FIELD_REQUIRED: ' Le champ client est requis.'
    }
  },
  SHARE_INSPECTION_MODAL: {
    STEP_1: {
      TITLE: 'Sélectionner la liste de contrôle',
      SUBTITLE:
        'Choisissez ce que vous souhaitez vérifier dans la liste des listes de contrôle ci-dessous.',
      NAME: 'Nom',
      CREATED: 'Créé',
      NEW_CHECKLIST: 'Nouvelle liste de contrôle'
    },
    STEP_2: {
      TITLE: "Sélectionner l'unité",
      SUBTITLE: "Sélectionnez l'unité que vous souhaitez faire vérifier.",
      NAME: 'Nom',
      OWNER: 'Propriétaire',
      CREATED: 'Créé',
      NEW_UNIT: 'Nouvelle unité'
    },
    STEP_3: {
      TITLE: 'Succès !',
      SUBTITLE:
        'Le contrôle {{id}} a été créé avec succès. Utilisez le formulaire ci-dessous pour le partager avec le vérificateur.',
      SEND_VIA: 'Envoyer via',
      EMAIL: 'E-mail',
      LINK: 'Lien',
      QR: 'Code QR',
      COPY: 'Copier',
      CANCEL: 'Annuler'
    }
  },
  CHECKS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Aperçu',
      SHARE: 'Partager'
    },
    HEADERS: {
      ID: 'ID',
      CHECKLIST: 'Liste de contrôle',
      UNIT: 'Unité',
      SCHEDULED: 'Planifié',
      DUE: 'Échéance',
      DUE_DATE: "Date d'échéance",
      ASSIGNED: 'Assigné',
      ASSIGNED_TO: 'Assigné à',
      STATUS: 'Statut'
    },
    STATUS: {
      OPEN: 'Ouvert',
      STARTED: 'Démarré',
      COMPLETED: 'Terminé',
      SCHEDULED: 'Planifié',
      CLOSED: 'Fermé'
    }
  },
  LOGO_ALT: 'Logo',
  CHECKLIST_ITEM_TYPE: {
    'Yes/No': 'Oui/Non',
    MEDIA: 'Média',
    TEXT: 'Texte',
    NUMBER: 'Nombre',
    RADIO_BUTTON: 'Boutons radio',
    CHECK_BOX: 'Cases à cocher',
    MULTIPLE_CHOICE: 'Choix multiples',
    RESPONSE_TYPES: 'Types de réponse',
    CUSTOM_OPTIONS: 'Options personnalisées',
    MULTI_MEDIA: 'Média',
    AI: 'IA',
    CONDITION: 'Condition',
    MULTIPLE_CHOICE_OPTIONS: {
      YES: 'Oui',
      NO: 'Non',
      NA: 'N/A',
      GOOD: 'Bon',
      FAIR: 'Juste',
      POOR: 'Mauvais',
      CONFORM: 'Conforme',
      NOT_CONFORM: 'Non conforme',
      PENDING: 'En attente',
      PLACEHOLDER: "Titre de l'option"
    },
    MULTIPLE_CHOICE_MODAL: {
      TITLE: 'Options de choix multiples',
      SUBTITLE: 'par exemple : Oui, Non, N/A',
      OPTION: 'Option',
      DELETE: 'Supprimer',
      ADD_OPTION_BUTTON: 'Ajouter une option (ou appuyez sur Entrée)',
      SAVE_BUTTON: 'Enregistrer',
      CANCEL_BUTTON: 'Annuler',
      MULTIPLE_SELECTION_TOGGLE: 'Sélection multiple',
      SCORING_TOGGLE: 'Évaluation',
      SCORE: 'Pointage'
    }
  },
  REPORTS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Aperçu',
      SHARE: 'Partager'
    },
    HEADERS: {
      IMAGE_URL: "URL de l'image",
      RULE: 'Règle',
      IMAGE_CAPTION: "Légende de l'image",
      WORDS_USED_TO_CHECK: 'Mots utilisés pour vérifier',
      DECISION: 'Décision',
      VERIFIED: 'Vérifié',
      VERIFICATION_PROMPT: 'Invite à la vérification',
      ASSIGNED: 'Assigné',
      ASSIGNED_TO: 'Assigné à',
      STATUS: 'Statut'
    },
    STATUS: {
      OPEN: 'Ouvert',
      STARTED: 'Commencé',
      COMPLETED: 'Complété',
      SCHEDULED: 'Planifié',
      CLOSED: 'Fermé'
    }
  },

  SHOW_MORE: '▼ Voir plus',
  SHOW_LESS: '▲ Voir moins',
  CONDITION_RESPONSES: {
    DAMAGED: 'Endommagé',
    POOR: 'Pauvre',
    FAIR: 'Équitable',
    GOOD: 'Bien',
    GREAT: 'Super'
  },
  SHOW_DETAILS: 'Voir les détails',
  CHECKLISTS: {
    NAME: 'Nom',
    CREATED_BY: 'Créé par',
    LAST_MODIFIED: 'Dernière modification',
    NEW_CHECKLIST: 'Nouvelle liste de contrôle'
  },
  NO_CHECKS_CREATED: 'Aucun contrôle n a été créé',
  NO_REPORTS_CREATED: 'Aucun rapport n a été créé',
  NO_CHECKLIST_CREATED: 'Aucune liste de contrôle n a été ajoutée à cette unité'
};

export default frJSON;

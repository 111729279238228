import { Client } from './client';
import { SortType } from './sort';
import { LogicDto } from './logic';

export interface Unit {
  id: string;
  title: string;
  description: string;
  imageURL: string;
  client: Client;
  checklistItems: ChecklistItem[];
  unitCategories: string[];
}

export interface Rentee {
  id: string;
  name: string;
}

export interface ChecklistItemBase {
  id?: number;
  title: string;
  type: ChecklistItemType;
  mandatory: boolean;
  isDeleted: boolean;
  order: number;
  checklistItemOptions: ChecklistItemOptions[];
  copyof?: number;
  subLogics?: LogicDto[];
  allowMultipleOptions?: boolean;
}

export interface ChecklistItem extends ChecklistItemBase {
  categoryName: string;
  categoryId: number;
  categoryOrder: number;
}
export interface ViewChecklistItem extends ChecklistItemBase {
  imageURL?: string;
  instruction?: string;
}

export interface ChecklistItemCategoryBasicInformation {
  id?: number;
  name: string;
  order: number;
  isDeleted: boolean;
}
export interface ChecklistItemCategory
  extends ChecklistItemCategoryBasicInformation {
  checklistItems?: ViewChecklistItem[];
}

export interface LocalCheckListItem extends ChecklistItem {
  index: number;
}

export interface ChecklistItemOptions {
  id: number;
  title: string;
  isDeleted?: boolean;
  order: number;
}

export enum YesNoType {
  YES,
  NO,
  NOT_SURE
}

export enum ChecklistItemType {
  YES_NO = 'Yes/No',
  /**
   * @deprecated
   * @Description split to multimedia & condition
   */
  MEDIA = 'MEDIA',
  MULTI_MEDIA = 'MULTI_MEDIA',
  CONDITION = 'CONDITION',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  /**
   * @deprecated
   * @Description combined into MULTIPLE_CHOICE
   */
  RADIO_BUTTON = 'RADIO_BUTTON',
  /**
   * @deprecated
   * @Description combined into MULTIPLE_CHOICE
   */
  CHECK_BOX = 'CHECK_BOX',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE'
}

export enum FlaggedOption {
  true = 'true',
  all = 'all'
}
export interface unitRow {
  id: number;
  title: string;
  imageURL: string;
  description: string;
  isFlagged: IsFlagged;
  reportsCount: string;
  lastSubmittedReportDate: number;
  createdAt: number;
  userName: string;
}

export enum IsFlagged {
  true = 'true',
  false = 'false',
  all = 'all'
}

export enum UnitSortKey {
  id = 'id',
  title = 'title',
  userName = 'userName',
  descirption = 'description',
  createdAt = 'createdAt',
  lastSubmittedReportDate = 'lastSubmittedReportDate'
}
export interface UnitSort {
  key: UnitSortKey;
  sort: SortType;
}

import {
  EmptyReport,
  EmptyReportCreatorInfo,
  ReportConfig,
  SignatureType
} from './../models/report';
import {
  clients,
  reports as reportsRequest,
  reportsSharingToken
} from 'src/axios/requests';
import { httpGet, httpPost, httpPut } from 'src/axios/axiosUtils';
import { useCallback, useState } from 'react';
import {
  Report,
  ReportCreatorInfo,
  ReportRow,
  ReportSort,
  ReportSortKey
} from 'src/models/report';
import { SortType } from 'src/models/sort';
import { FlaggedOption, IsFlagged } from 'src/models/unit';
import useRefMounted from './useRefMounted';
import { PaginationMeta } from 'src/models/pagination';
import * as FileSaver from 'file-saver';
import { LoadingState } from 'src/axios/types';
import { useStores } from './useMobxStores';
import { PAGE_SIZES } from 'src/constants/report-config';
import useAuth from './useAuth';

export const useReports = () => {
  const isMountedRef = useRefMounted();
  const [reports, setReports] = useState<ReportRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportDetailsState, setReportDetailsState] = useState(
    LoadingState.IDLE
  );
  const [reportState, setReportState] = useState(LoadingState.IDLE);
  const [take, setTake] = useState(PAGE_SIZES[0]);
  const [page, setPage] = useState(0);
  const [allReportsCount, setAllReportsCount] = useState(0);
  const [flagOption, setFlagOption] = useState(FlaggedOption.all);
  const [reportSort, setReportSort] = useState<ReportSort>({
    key: ReportSortKey.createdAt,
    sort: SortType.desc
  });
  const [reportDetails, setReportDetails] = useState<Report>(EmptyReport);
  const [isClientSignedToTheReport, setIsClientSignedToTheReport] =
    useState(false);
  const { user } = useAuth();

  const [reportCreatorInfo, setReportCreatorInfo] = useState<ReportCreatorInfo>(
    EmptyReportCreatorInfo
  );
  const {
    backend: { reportStore: reportStore }
  } = useStores();

  const {
    backend: {
      reportStore: { translationLanguage }
    }
  } = useStores();
  const [FreeReportsCount, setFreeReportsCount] = useState<number>(0);

  const updateReportFlag = async (reportId: number, isFlagged: IsFlagged) => {
    try {
      setLoading(true);
      await httpPut(`${reportsRequest}/${reportId}/flag`, {
        isFlagged
      });
      const reportsClone = [...reports];
      const findIndex = reportsClone.findIndex((r) => r.reportId === reportId);
      if (findIndex >= 0) {
        reportsClone[findIndex] = {
          ...reportsClone[findIndex],
          isFlagged
        };
        setReports(reportsClone);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getReports = useCallback(
    async (
      apiUrl: string,
      clientId?: string,
      filterOption = FlaggedOption.all,
      reportTake = 50,
      reportPage = 0
    ) => {
      setReportState(LoadingState.LOADING);
      setLoading(true);
      try {
        const clientReportsParams = new URLSearchParams({
          flagged: filterOption,
          take: `${reportTake}`,
          page: `${reportPage + 1}`,
          'sort-by': reportSort.key,
          'sort-order': reportSort.sort
        }).toString();

        const response = await httpGet(
          `${apiUrl}?${clientReportsParams}`,
          false
        );
        setReportState(LoadingState.DONE);
        setLoading(false);
        if (isMountedRef.current) {
          setReports(response.data);
          const meta: PaginationMeta = response.meta;
          setAllReportsCount(meta.itemCount);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
        setReportState(LoadingState.FAILED);
      }
    },
    [isMountedRef, reportSort.key, reportSort.sort, flagOption]
  );

  const getReportDetails = useCallback(
    async (reportId: number, token?: string) => {
      try {
        setReportDetailsState(LoadingState.LOADING);
        let reportDetailsReq = `${reportsRequest}/${reportId}`;
        if (token) {
          reportDetailsReq += `?token=${token}`;
        }
        let config = {};
        if (translationLanguage) {
          config = {
            headers: {
              'translation-lang': translationLanguage
            }
          };
        }
        const reportResponse: Report = await httpGet(
          reportDetailsReq,
          false,
          config
        );
        if (isMountedRef.current) {
          setReportDetails(reportResponse);
          setIsClientSignedToTheReport(
            !!reportResponse.signatures.find(
              (r) => r.type === SignatureType.CLIENT
            )
          );
          setReportDetailsState(LoadingState.DONE);
        }
      } catch (err) {
        setReportDetailsState(LoadingState.FAILED);
        console.error(err);
      }
    },
    [isMountedRef, translationLanguage]
  );

  const getReportCreatorInfo = useCallback(
    async (reportId: number) => {
      try {
        const reportCreatorResponse: ReportCreatorInfo = await httpGet(
          `/${reportsRequest}/${reportId}/creators-information`,
          false
        );
        if (isMountedRef.current) {
          setReportCreatorInfo(reportCreatorResponse);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef]
  );

  const generateReportPDF = async (
    unitId: number,
    reportId: number,
    token?: string
  ) => {
    let reportDetailsReq = `${reportsRequest}/${reportId}`;
    if (token) {
      reportDetailsReq += `?token=${token}`;
    }
    let config = {
      responseType: 'blob' as any,
      headers: {
        Accept: 'application/pdf'
      }
    };
    console.log('rrrrrrrrrrr', translationLanguage);
    if (translationLanguage) {
      config.headers['translation-lang'] = translationLanguage;
    }
    const response: any = await httpGet(reportDetailsReq, false, config);
    await FileSaver.saveAs(response, `Report_${reportId}.pdf`);
  };

  const generateReportSharingToken = async (reportId: number) => {
    const response = await httpPost(reportsSharingToken(reportId), false);
    if (reportDetails) {
      reportDetails.sharingTokens.push({
        token: response
      });
      setReportDetails({
        ...reportDetails
      });
    }
  };

  const generateReportXlsx = async (
    unitId: number,
    reportId: number,
    token?: string
  ) => {
    let reportDetailsReq = `${reportsRequest}/${reportId}`;
    if (token) {
      reportDetailsReq += `?token=${token}`;
    }
    let config = {
      responseType: 'blob' as any,
      headers: {
        Accept: 'text/csv'
      }
    };
    if (translationLanguage) {
      config.headers['translation-lang'] = translationLanguage;
    }
    const response: any = await httpGet(reportDetailsReq, false, config);
    await FileSaver.saveAs(response, `Report_${reportId}.xlsx`);
  };

  const groupCheckListByCategory = (reportChecklistItems) => {
    const tempCategorizedItems = {};
    reportChecklistItems.forEach((item) => {
      const categoryName = item.checklistItem.categoryName || '';
      const category = tempCategorizedItems[categoryName] || {
        items: [],
        maxScore: 0,
        actualScore: 0
      };
      tempCategorizedItems[categoryName] = {
        items: [...category.items, item],
        maxScore: category.maxScore + (item.maxScore ?? 0),
        actualScore: category.actualScore + (item.actualScore ?? 0)
      };
    });
    return tempCategorizedItems;
  };

  const updateReportConfigs = async (
    reportId: number,
    values: ReportConfig[]
  ): Promise<ReportConfig[]> => {
    try {
      setLoading(true);
      const configs = await httpPut(`${reportsRequest}/${reportId}/configs`, {
        configs: values
      });
      return configs;
    } finally {
      setLoading(false);
    }
  };

  const updateReportSummary = async (reportId: number, summary: string) => {
    try {
      setLoading(true);
      await httpPut(`${reportsRequest}/${reportId}/summary`, { summary });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getFreeReportsCount = async () => {
    try {
      const dataa = await httpGet(
        `${clients}/${user.client.id}/freeReportsCount`
      );
      window.localStorage.setItem('freeReportCount', String(dataa));
      reportStore.setFreeReportsCount(dataa);
    } catch (error) {
      console.log('dataerrorcca', error);
    }
  };

  return {
    reports,
    allReportsCount,
    reportSort,
    setReportSort,
    loading,
    setLoading,
    getReports,
    updateReportFlag,
    take,
    setTake,
    page,
    setPage,
    flagOption,
    setFlagOption,
    getReportDetails,
    reportDetails,
    getReportCreatorInfo,
    reportCreatorInfo,
    generateReportPDF,
    generateReportXlsx,
    groupCheckListByCategory,
    reportState,
    generateReportSharingToken,
    isClientSignedToTheReport,
    reportDetailsState,
    updateReportConfigs,
    updateReportSummary,
    getFreeReportsCount
  };
};

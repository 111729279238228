import {
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  TableCell,
  TextField,
  colors,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as S from './styled';
import { Formik, useFormikContext } from 'formik';
import { ChecklistItemBase, ChecklistItemType } from 'src/models/unit';
import {
  getType,
  isDisabledMandatoryControl,
  isShowOptionInput
} from '../../utils';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { LabelInfo, LabelSuccess, LabelWarning } from 'src/components/Label';
import { FormTextField } from 'src/components/FormFields';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import TagsInput from 'src/components/TagsInput';
import { ViewChecklistItem } from 'src/models/unit';
import * as Yup from 'yup';
import { ChecklistItemOption } from 'src/models/report';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { StyledMuiTextField } from 'src/components/ChecklistItemsFormUI/styled';
import ConfirmationDialog from 'src/components/Dialogs/confirmationDialog';
import _ from 'lodash';
import { reaction } from 'mobx';
import { TypeDropdown } from '../TypeDropdown';

export type QuestionFormProps = {
  item: ViewChecklistItem;
  children: ReactElement;
  dragHandleProps: any;
  inputRef: any;
  isLastItem?: boolean;
  isReadOnly?: boolean;
  categoryId: number;
  onSubmit: (values, deleteSubLogics: boolean) => void;
  onAddCriteria?: () => void;
  onEnterKeyDown: () => void;
  onClick: () => void;
};

const validationSchema = (t: any) =>
  Yup.object().shape({
    title: Yup.string()
      .max(255)
      .required(t('CHECKLIST_BUILDER.CRITERIA.ERRORS.REQUIRED')),
    type: Yup.string().max(255).required(),
    selectOptions: Yup.array()
      .of(
        Yup.object().shape({
          isDeleted: Yup.boolean()
        })
      )
      .compact((v) => v.isDeleted)
      .when('type', {
        is: (val) =>
          [
            ChecklistItemType.CHECK_BOX,
            ChecklistItemType.RADIO_BUTTON
          ].includes(val),
        then: Yup.array()
          .min(2, t('CHECKLIST_BUILDER.CRITERIA.ERRORS.MIN'))
          .max(99, t('CHECKLIST_BUILDER.CRITERIA.ERRORS.MAX'))
      })
  });

export const CriteriaForm = ({
  item,
  children,
  isLastItem = false,
  isReadOnly,
  dragHandleProps,
  inputRef,
  categoryId,
  onSubmit: onFormSubmit,
  onEnterKeyDown,
  onClick
}: QuestionFormProps) => {
  const { t }: any = useTranslation();
  const theme = useTheme();
  const formRef = useRef<{ dirty: boolean; isValid: boolean; values: any }>();
  const formInitialValues = () => ({
    type: item?.type || ChecklistItemType.YES_NO,
    title: item?.title || '',
    mandatory: typeof item?.mandatory === 'boolean' ? item?.mandatory : true,
    selectOptions:
      _.sortBy(
        item?.checklistItemOptions?.filter((e) => !e.isDeleted),
        'order'
      ) || [],
    id: item?.id,
    submit: null
  });
  const [formValues, setFormValues] = useState(formInitialValues());
  const [mandatory, setMandatory] = useState(item.mandatory);
  const [highestOrder, setHighestOrder] = useState(
    Math.max(
      ...(item?.checklistItemOptions
        ?.filter((e) => !e.isDeleted)
        .map((e) => e.order) ?? []),
      0
    )
  );

  const [showChangeTypeConfirmation, setShowChangeTypeConfirmation] =
    useState(false);

  reaction(
    () => item.checklistItemOptions,
    () => {
      setFormValues(formInitialValues());
    }
  );

  const onSubmit = (values) => {
    if (values.type != item.type && item.subLogics?.length > 0) {
      setShowChangeTypeConfirmation(true);
    } else {
      onFormSubmit(values, false);
    }
  };

  const changeType = (confirmed: boolean) => {
    if (confirmed) {
      onFormSubmit(formValues, true);
    } else {
      setFormValues(formInitialValues());
    }
  };

  useEffect(() => {
    return () => {
      if (formRef?.current?.dirty && formRef?.current?.isValid) {
        onSubmit(formRef.current.values);
      }
    };
  }, []);

  return (
    <>
      <Formik
        validateOnBlur
        initialValues={formValues}
        validationSchema={validationSchema(t)}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          setFormValues(values);
        }}
        enableReinitialize
      >
        {({
          errors,
          handleChange,
          touched,
          values,
          isValid,
          submitForm,
          setFieldValue,
          handleBlur,
          dirty
        }) => {
          formRef.current = {
            values,
            dirty,
            isValid
          };
          return (
            <form>
              <S.Container
                container
                {...dragHandleProps}
                className="criteria-form"
                $isLastItem={isLastItem}
              >
                <S.TitleContainer
                  item
                  xs={12}
                  sm={7}
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                >
                  <DragIndicatorIcon />
                  <S.StyledMuiTextField
                    fullWidth
                    name="title"
                    placeholder={t(
                      'CHECKLIST_BUILDER.CRITERIA.TITLE_PLACEHOLDER'
                    )}
                    trim={true}
                    inputRef={inputRef}
                    onEnterKeyDown={onEnterKeyDown}
                    disabled={isReadOnly}
                    multiline
                    id={`criteria-container-${item.id}`}
                  />
                </S.TitleContainer>
                <S.FlexContainer item xs={7} sm={3}>
                  <TypeDropdown
                    checklistItemState={item}
                    onTransitionEnd={() => {
                      if (inputRef.current) {
                        inputRef.current?.focus();
                      }
                    }}
                    isReadOnly={isReadOnly}
                    categoryId={categoryId}
                  />
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ backgroundColor: theme.borders.color }}
                  />
                </S.FlexContainer>
                <S.FlexContainer
                  item
                  xs={5}
                  sm={2}
                  justifyContent={'space-between'}
                >
                  <S.StyledCheckbox
                    name="mandatory"
                    label={t('CHECKLIST_BUILDER.CRITERIA.REQUIRED_LABEL')}
                    control={
                      <Checkbox
                        onBlur={(e) => {
                          handleBlur(e);
                          if (isValid) submitForm();
                        }}
                        checked={mandatory}
                        onChange={(e) => {
                          handleChange(e);
                          setMandatory(e?.target?.checked);
                        }}
                        disabled={isDisabledMandatoryControl({
                          type: values.type,
                          allowMultipleOptions: item.allowMultipleOptions
                        } as ViewChecklistItem)}
                      />
                    }
                    disabled={isReadOnly}
                  />
                  {children}
                </S.FlexContainer>
                <ConditionalWrapper
                  show={isShowOptionInput(values.type as ChecklistItemType)}
                >
                  <S.OptionsContainer item xs={12}>
                    <TagsInput
                      error={Boolean(
                        touched.selectOptions && errors.selectOptions
                      )}
                      variant="outlined"
                      placeholder={t('SELECT_OPTIONS_HOLDER')}
                      value={values.selectOptions.filter((e) => !e.isDeleted)}
                      helperText={touched.selectOptions && errors.selectOptions}
                      disabled={isReadOnly}
                      onClick={onClick}
                      onBlur={(e) => {
                        handleBlur({
                          ...e,
                          target: {
                            ...e.target,
                            name: 'selectOptions'
                          }
                        });
                        if (isValid && dirty) submitForm();
                      }}
                      onAdd={(chip) => {
                        handleChange({
                          target: {
                            value: [
                              ...values.selectOptions,
                              {
                                title: chip,
                                order: highestOrder + 1
                              }
                            ],
                            name: 'selectOptions'
                          }
                        });
                        setHighestOrder(highestOrder + 1);
                      }}
                      onDelete={(chip, index) => {
                        if (!isReadOnly) {
                          const newValues = values.selectOptions.map((e) => ({
                            ...e
                          }));

                          if (newValues.filter((e) => !e.isDeleted)[index])
                            newValues.filter((e) => !e.isDeleted)[
                              index
                            ].isDeleted = true;

                          handleChange({
                            target: {
                              value: newValues,
                              name: 'selectOptions'
                            }
                          });
                        }
                      }}
                    />
                  </S.OptionsContainer>
                </ConditionalWrapper>
              </S.Container>
            </form>
          );
        }}
      </Formik>
      <ConfirmationDialog
        open={showChangeTypeConfirmation}
        onConfirm={() => {
          changeType(true);
        }}
        setOpen={setShowChangeTypeConfirmation}
        title={t('CHECKLIST_BUILDER.CRITERIA.CHANGE_TYPE_CONFIRMATION_MESSAGE')}
        onCancel={() => changeType(false)}
        onClose={() => changeType(false)}
      />
    </>
  );
};

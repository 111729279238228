import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Modal, styled, Typography, Zoom } from '@mui/material';
import { ShareInspectionFormModalProps } from './types';
import { ShareInspectionForm } from '../ShareInspectionForm';
import { useStores } from '../../hooks/useMobxStores';
import { useTranslation } from 'react-i18next';
import { GenerateUnitTokenDto, Token, TokenType } from '../../models/token';
import { useSnackbar } from 'notistack';
import {
  TemplateSortKey,
  TemplateSortParams
} from '../../content/dashboards/Checklist/SelectTemplateModal/types';
import { SortOrder, SortType } from '../../models/sort';
import { useNavigate } from 'react-router';
import { InspectionEvent } from '../../hooks/useAnalytics/AnalyticsEvent';
import useAuth from '../../hooks/useAuth';
import useAnalytics from '../../hooks/useAnalytics';
import useRefMounted from '../../hooks/useRefMounted';
import UnitsTable from '../UnitsResultsTable';
import { ShareInspectionChecklistCoiceFormModal } from '../ShareInspectionChecklistCoiceFormModal';
import { ChecklistSortKey } from '../../stores/backend/checklist/types';
import * as S from './styled';
import ConditionalWrapper from '../ConditionalWrapper';
import BackendResourceWrapper from '../BackendResourceWraper';
import { observer } from 'mobx-react';
import { TemplatesList } from './components/TemplatesList';
import { GetTemplateDto } from 'src/stores/backend/template/types';
import { UnitsList } from './components/UnitsList';
import { IconButton } from '@mui/material';
import { LoadingState } from 'src/axios/types';
import SaveBasicInformation from 'src/content/dashboards/Units/UnitForm/components/SaveBasicInformation';

export const CreateCheckFormModal: FC<ShareInspectionFormModalProps> = observer(
  ({ open, templateId, unitId, checklistId, handleClose }) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedChecklistId, setSelectedChecklistId] = useState<
      number | number
    >(checklistId);
    const [selectedUnitId, setSelectedUnitId] = useState<number | undefined>(
      unitId
    );
    const [newUnitModalOpen, setNewUnitModalOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] =
      useState<number>(templateId);
    const {
      backend: { checklistStore, checksStore, unit }
    } = useStores();

    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
      if (!open) return;

      if (templateId || checklistId) {
        handleNext();
      }

      if (unitId) {
        setActiveStep(-2);
        onSelectUnit(unitId);
      }
    }, [open]);

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedChecklistId(undefined);
      setSelectedUnitId(undefined);
      setSelectedTemplateId(undefined);
    };
    const handleCloseModal = () => {
      handleClose();
      setActiveStep(0);
      setSelectedChecklistId(undefined);
      setSelectedUnitId(undefined);
      setSelectedTemplateId(undefined);
    };

    const onSelectUnit = async (unitId: number): Promise<void> => {
      setSelectedUnitId(unitId);
      handleNext();
      checksStore
        .createCheck(unitId, checklistId, selectedTemplateId)
        .then(({ id, checklistUrl, token }) => {
          onCheckCreated(id);
        });
    };

    const onCheckCreated = (id: number) => {
      navigate(
        `/checkfirst/dashboard/clients/${user?.client?.id}/checks?checkId=${id}`
      );
      handleCloseModal();
    };

    return (
      <>
        <S.StyledModal
          open={open && activeStep >= 0}
          onClose={handleCloseModal}
        >
          <S.ModalBox
            sx={{ visibility: newUnitModalOpen ? 'hidden' : 'visible' }}
          >
            <S.ModalBoxHeader>
              {activeStep === 0 && (
                <S.HeaderContainer>
                  <Typography variant="h3">
                    {t('SHARE_INSPECTION_MODAL.STEP_1.TITLE')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t('SHARE_INSPECTION_MODAL.STEP_1.SUBTITLE')}
                  </Typography>
                </S.HeaderContainer>
              )}
              {activeStep >= 1 && (
                <S.HeaderContainer>
                  <Typography variant="h3">
                    {t('SHARE_INSPECTION_MODAL.STEP_2.TITLE')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t('SHARE_INSPECTION_MODAL.STEP_2.SUBTITLE')}
                  </Typography>
                </S.HeaderContainer>
              )}
              <IconButton onClick={handleCloseModal}>
                <S.StyledCloseIcon />
              </IconButton>
            </S.ModalBoxHeader>

            <S.ModalBoxContent>
              <Box>
                {activeStep === 0 && (
                  <TemplatesList
                    templateId={selectedTemplateId}
                    showNewTemplateButton
                    onChange={(template) => {
                      setSelectedTemplateId(template?.id);
                      handleNext();
                    }}
                    onNewTemplateCreated={(newTemplate) => {
                      navigate(
                        `dashboard/templates/${newTemplate.id}/checklist-items`
                      );
                      handleClose();
                    }}
                  />
                )}
                {activeStep === 1 && (
                  <UnitsList
                    selectedUnitId={selectedUnitId}
                    onNewUnitClicked={() => setNewUnitModalOpen(true)}
                    onChange={(newId) => onSelectUnit(newId)}
                  />
                )}
                {activeStep === 2 && (
                  <BackendResourceWrapper
                    states={[LoadingState.LOADING]}
                  ></BackendResourceWrapper>
                )}
              </Box>
            </S.ModalBoxContent>

            {activeStep === 0 && (
              <S.ModalBoxFooter>
                <Button variant="text" onClick={handleCloseModal}>
                  {t('Cancel')}
                </Button>
              </S.ModalBoxFooter>
            )}

            {activeStep === 1 && (
              <S.ModalBoxFooter>
                <Button variant="text" onClick={handleBack}>
                  {t('Back')}
                </Button>
              </S.ModalBoxFooter>
            )}
          </S.ModalBox>
        </S.StyledModal>
        <SaveBasicInformation
          open={newUnitModalOpen}
          handleClose={(savedUnitId) => {
            setNewUnitModalOpen(false);
            if (savedUnitId) setSelectedUnitId(savedUnitId);
          }}
          unitId={undefined}
        />
      </>
    );
  }
);

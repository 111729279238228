import BackendResourceWrapper from 'src/components/BackendResourceWraper';
import { useStores } from 'src/hooks/useMobxStores';
import * as S from '../../styled';
import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import ArticleTwoTone from '@mui/icons-material/ArticleTwoTone';
import { IdWrapper } from 'src/components/IdWrapper';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { UnitSortKey } from 'src/models/unit';
import { SortOrder, SortType } from 'src/models/sort';
import moment from 'moment';
import {
  TemplateSortKey,
  TemplateSortParams
} from 'src/components/CreateChecklistFormModal/types';
import { GetTemplateDto } from 'src/stores/backend/template/types';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { toggleSortOrder } from 'src/utils/table';
interface Props {
  templateId: number;
  showNewTemplateButton?: boolean;
  onChange: (template: GetTemplateDto) => void;
  onNewTemplateCreated?: (template: GetTemplateDto) => void;
}
export const TemplatesList = observer(
  ({
    templateId,
    showNewTemplateButton,
    onChange,
    onNewTemplateCreated
  }: Props) => {
    const { t } = useTranslation();

    const {
      backend: { templateStore }
    } = useStores();

    const [sortState, setSortState] = useState<TemplateSortParams>(undefined);

    useEffect(() => {
      templateStore.getTemplates({ sort: sortState });
    }, [sortState]);

    const onSort = (key: TemplateSortKey) => {
      const newSort = toggleSortOrder(sortState, key) as TemplateSortParams;
      setSortState(newSort);
    };

    const onCreateNewTemplate = async () => {
      const newTemplate = await templateStore.createTemplate({
        title: t('UNTITLED_CHECKLIST'),
        imageURL: ''
      });
      onNewTemplateCreated?.(newTemplate);
    };

    return (
      <BackendResourceWrapper states={[templateStore.templatesState]}>
        <S.StyledTableContainer>
          <Table sx={{ tableLayout: 'fixed' }}>
            <TableHead>
              <S.StyledTableRow>
                <S.StyledHeaderTableCell
                  onClick={() => onSort(TemplateSortKey.title)}
                >
                  <S.StyledHeaderLabel
                    direction={
                      sortState?.key == TemplateSortKey.title
                        ? sortState?.order
                        : undefined
                    }
                  >
                    {t('SHARE_INSPECTION_MODAL.STEP_1.NAME')}
                  </S.StyledHeaderLabel>
                </S.StyledHeaderTableCell>
                <S.CreatedAtCell
                  onClick={() => onSort(TemplateSortKey.createdAt)}
                >
                  <S.StyledHeaderLabel
                    direction={
                      sortState?.key == TemplateSortKey.createdAt
                        ? sortState?.order
                        : undefined
                    }
                  >
                    {t('SHARE_INSPECTION_MODAL.STEP_1.CREATED')}
                  </S.StyledHeaderLabel>
                </S.CreatedAtCell>
              </S.StyledTableRow>
            </TableHead>
            <TableBody>
              {showNewTemplateButton && (
                <S.Row
                  key={`new-template`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onCreateNewTemplate();
                  }}
                >
                  <S.StyledTableCell colSpan={2}>
                    <S.AddNewButton>
                      <AddIcon />
                      {t('SHARE_INSPECTION_MODAL.STEP_1.NEW_CHECKLIST')}
                    </S.AddNewButton>
                  </S.StyledTableCell>
                </S.Row>
              )}
              {templateStore.templates.map((template) => (
                <S.Row
                  key={`template-${template.id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange?.(template);
                  }}
                >
                  <S.StyledTableCell>
                    <IdWrapper
                      id={template.title}
                      width="100%"
                      lineHeight="1.45"
                      showTooltip={true}
                    />
                  </S.StyledTableCell>
                  <S.StyledTableCell>
                    {moment(template.createdAt).format('DD/MM/YY, HH:mm')}
                  </S.StyledTableCell>
                </S.Row>
              ))}
            </TableBody>
          </Table>
        </S.StyledTableContainer>
      </BackendResourceWrapper>
    );
  }
);

const aeJSON = {
  NO: 'Não',
  YES: 'Sim',
  NOT_SURE: 'Não tenho certeza',
  NA: 'N/D',
  REPORT_DETAILS_HEADER_TITLE: 'Detalhes do relatório',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'baixar PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'BaixarXLSX',
  REPORT_DETAILS_NO_ANSWER: 'Sem resposta',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Responsabilidade',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'Esta seção mostra quem executou o relatório',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Verificação',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Todos os dados verificados com Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unidade',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspeção',
  ISSUES_HEADER: 'Sinalizado',
  ISSUES_SUBHEADER: 'Gerenciar problemas encontrados durante a inspeção',
  ISSUES_PRIORITY: 'Prioridade',
  ISSUES_REPORTED: 'problemas foram relatados',
  COMPLETED_AT: 'Concluído em',
  BY: 'por',
  ON: 'sobre',
  CRITERIA_CHECKED: 'critérios foram verificados em',
  SECTIONS: 'Seções',
  NO_FLAGGED_ITEMS: 'Nenhum item foi sinalizado',
  FLAGGED_ITEMS: 'itens foram sinalizados em',
  CHECKER: 'verificador relatou que eles estavam',
  NO_ISSUES_RAISED: 'O relatório não tem problemas',
  ISSUE_RAISED: 'Questão levantada',
  REMARK: 'Observação',
  LOCATION: 'Localização',
  UPPERCASE_TITLE: 'TÍTULO',
  STATUS: 'STATUS',
  UPPERCASE_UNIT: 'UNIDADE',
  UNIT: 'Unidade',
  DEVICE_DETAILS: 'Detalhes do dispositivo',
  DATE: 'Data',
  NAME: 'Nome',
  SIGNATURE: 'Assinatura',
  COUNTER_SIGNATURE_CREATED: 'Contra assinatura',
  CO_SIGNED: 'Co-assinado',
  CO_SIGNATURE: 'Co-assinatura',
  IP_ADDRESS: 'Endereço de IP',
  SIGN: 'Sinal',
  DOWNLOAD: 'Download',
  DOWNLOADED: 'Baixado com sucesso',
  CHECKLIST: 'Lista de controle',
  IMAGES: 'IMAGENS',
  REPORTED: 'RELATADO',
  ID: 'EU IA',
  HIGH: 'Alto',
  MEDIUM: 'Médio',
  LOW: 'Baixo',
  SAVED: 'Salvo com sucesso',
  REPORT_SUMMARY: 'Resumo do relatório',
  CRITERIA: 'Critério',
  RESPONSE: 'Resposta',
  ISSUE_ID: 'ID do problema',
  ISSUES_TABLE: 'Tabela de problemas',
  SECTION: 'Seção',
  CONDITION_RESPONSES: {
    DAMAGED: 'Danificado',
    POOR: 'Pobre',
    FAIR: 'Justo',
    GOOD: 'Bom',
    GREAT: 'Ótimo'
  },
  CUSTOMISE: 'Personalizar',
  REPORT_CONFIG: {
    SHOW_SUMMARY: 'Resumo do relatório',
    SHOW_ISSUES: 'Problemas levantados',
    SHOW_FLAGGED: 'Itens sinalizados',
    SHOW_SCORING: 'Pontuação',
    SHOW_CO_SIGNATURE: 'Co-assinatura'
  },
  SCORING_SUMMARY: {
    SCORING: 'Pontuação',
    ITEM_TYPES: {
      SINGLE_CHOICE: 'Escolha múltipla',
      'Yes/No': 'Sim/Não',
      MULTI_MEDIA: 'Multimídia',
      CONDITION: 'Condição',
      TEXT: 'Texto',
      MULTIPLE_CHOICE: 'Escolha múltipla - resposta múltipla'
    },
    TABLE: {
      NUMBER: 'Nº',
      SECTION: 'Seção',
      OBTAINED: 'Obtido',
      POSSIBLE: 'Possível',
      SCORE: 'Pontuação',
      TOTAL: 'Total'
    }
  },
  FLAGGED_ITEMS_ACCORDION_SUMMARY: 'Itens sinalizados',
  SECTION_NAME: 'Seção'
};
export default aeJSON;

const esJSON = {
  AUTH_SIGN_UP_CARD_TITLE: 'Inscribirse',
  AUTH_SIGN_UP_CARD_SUB_TITLE:
    'Complete los campos a continuación para registrarse para obtener una cuenta.',
  AUTH_SIGN_UP_CARD_GOOGLE_BUTTON: 'Regístrate con Google',
  AUTH_SIGN_UP_CARD_OR_SEPARATOR: 'o',
  AUTH_SIGN_UP_CARD_NAME_FIELD_LABEL: 'Nombre',
  AUTH_SIGN_UP_CARD_NAME_FIELD_ERROR: 'El campo de nombre es obligatorio.',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_LABEL: 'Correo electrónico',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_REQUIRED:
    'El campo de correo electrónico es obligatorio.',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_INVALID:
    'El correo electrónico proporcionado debe ser una dirección de correo electrónico válida.',
  AUTH_SIGN_UP_CARD_PASS_FIELD_LABEL: 'Contraseña',
  AUTH_SIGN_UP_CARD_PASS_FIELD_ERROR: 'El campo de contraseña es obligatorio',
  AUTH_SIGN_UP_CARD_TERMS_AGREE_STATEMENT: 'acepto el',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS: 'Términos y condiciones',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS_ERROR:
    'Debes aceptar nuestros términos y condiciones.',
  AUTH_SIGN_UP_CARD_BUTTON: 'Inscribirse',
  AUTH_SIGN_UP_CARD_HAVE_ACCOUNT: '¿Ya tienes una cuenta?',
  AUTH_SIGN_UP_CARD_LOGIN_LINK: 'Firme aquí',
  AUTH_SIGN_UP_SIDEBAR_TITLE: 'Cree informes de inspección digitales',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_1: 'Panel de gestión de inspección',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_2: 'Aplicación de inspección fácil de usar',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_3:
    'Informes de inspección digitales basados ​​en dispositivos móviles',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_4: 'Pruebe 10 informes gratuitos por mes',
  AUTH_LOGIN_CARD_EMAIL_FIELD_LABEL: 'Correo electrónico',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_REQUIRED:
    'El campo de correo electrónico es obligatorio.',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_INVALID:
    'El correo electrónico proporcionado debe ser una dirección de correo electrónico válida.',
  AUTH_LOGIN_CARD_PASS_FIELD_LABEL: 'Contraseña',
  AUTH_LOGIN_CARD_PASS_FIELD_ERROR: 'El campo de contraseña es obligatorio',
  AUTH_LOGIN_CARD_GOOGLE_BUTTON: 'Inicia sesión con Google',
  AUTH_LOGIN_CARD_BUTTON: 'Iniciar sesión',
  AUTH_LOGIN_CARD_FORGOT_PASSWORD: '¿Has olvidado tu contraseña?',
  AUTH_LOGIN_CARD_RESET_PASSWORD_LINK: 'Restablecer la contraseña',
  AUTH_LOGIN_CARD_NO_ACCOUNT: '¿No tienes una cuenta?',
  AUTH_LOGIN_CARD_REGISTER_LINK: 'Registro',
  AUTH_LOGIN_CARD_ERROR_INVALID_CREDENTIALS: 'Correo o contraseña incorrectos',
  USERS_LIST_PAGE_HEADER_TITLE: 'Usuarios',
  USERS_LIST_PAGE_HEADER_CAPTION: 'Administra tus usuarios',
  USERS_LIST_EMPTY_MESSAGE: 'Sin usuarios',
  USERS_LIST_PAGE_HEADER_CREATE_BUTTON: 'Crear nuevo usuario',
  USERS_LIST_DELETE_USER_CONFIRMATION_MESSAGE:
    '¿Realmente deseas eliminar este Usuario?',
  USERS_LIST_DELETE_USER_BUTTON_TOOLTIP: 'Borrar',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_TITLE: 'usuario creado',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_PASSWORD:
    'El usuario fue creado con la contraseña:',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL:
    'También se ha enviado un correo de invitación al usuario en',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL_EXTENDED:
    ', pueden usar el enlace en ese correo electrónico para establecer su propia contraseña.',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_CAPTION:
    'Utilice esta contraseña para iniciar sesión',
  USERS_LIST_CREATE_USER_DIALOG_TITLE: 'Crear nuevo usuario',
  USERS_LIST_CREATE_USER_DIALOG_CAPTION: 'Agregar un nuevo usuario',
  USERS_LIST_CREATE_USER_DIALOG_CREATE_BUTTON: 'Crear usuario',
  REPORT_DETAILS_HEADER_TITLE: 'Detalles del informe',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'Descargar PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Descargar XLSX',
  REPORT_DETAILS_NO_ANSWER: 'Sin respuesta',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Responsabilidad',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'Esta sección muestra quién realizó el informe.',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Verificación',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Todos los datos verificados con Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unidad',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspección',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_TITLE: '¿Está seguro?',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_SUBTITLE:
    'Esto también eliminará cualquier elemento de esta categoría.',
  COPIED: 'Copiado exitosamente...',
  NO_ACTIVITY_MESSAGE:
    'Los detalles de la actividad aún no están disponibles...',
  COMMENT: 'COMENTARIO',
  configurations: 'configuraciones',
  new_configuration: 'nueva configuración',
  Key: 'Llave',
  Title: 'Título',
  Actions: 'Comportamiento',
  Clients: 'Clientela',
  Value: 'Valor',
  Edit: 'Editar',
  Cancel: 'Cancelar',
  Save: 'Ahorrar',
  Saved: 'Salvado',
  OPTIONS: 'Opciones',
  FORGET_PASSWORD: 'olvidar contraseña',
  UPDATE_PASSWORD_TITLE: 'Actualiza contraseña',
  CONFIRM_PASSWORD_VALIDATION:
    'Ingrese la misma contraseña en los campos de Contraseña y Confirmar contraseña',
  PASSWORD_VALIDATION: 'El campo de contraseña es obligatorio',
  TITLE_VALIDATION: 'El campo del título es obligatorio.',
  PASSWORD_UPDATED:
    'La contraseña se actualizó correctamente. Intente iniciar sesión.',
  ERROR: 'Algo salió mal',
  PASSWORD: 'Contraseña',
  CONFIRM_PASSWORD: 'confirmar Contraseña',
  UPDATE_PASSWORD: 'Actualiza contraseña',
  UPDATE_USER: 'Actualizar usuario',
  INVALID_EMAIL: 'Email inválido',
  UPDATE_PASSWORD_TOGGEL: 'Actualiza contraseña',
  NAME_VALIDATION: 'El campo de nombre es obligatorio.',
  EMAIL_VALIDATION: 'El campo de correo electrónico es obligatorio.',
  MESSAGE_VALIDATION: 'El campo de mensaje es obligatorio',
  SHOULD_VALID_EMAIL:
    'El correo electrónico proporcionado debe ser una dirección de correo electrónico válida.',
  IMAGE_VALIDATION: 'El campo de imagen es obligatorio.',
  NAME_PLACEHOLDER: 'Nombre...',
  NAME_LABEL: 'Nombre',
  EMAIL_PLACEHOLDER: 'Correo electrónico...',
  EMAIL_LABEL: 'Correo electrónico',
  MESSAGE_LABEL: 'Mensaje',
  CLIENT_IMAGE_LABEL: 'Imagen',
  PASSWORD_PLACEHOLDER: 'Contraseña...',
  PASSWORD_LABEL: 'Contraseña',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirmar Contraseña...',
  CONFIRM_PASSWORD_LABEL: 'confirmar Contraseña',
  SAVE_USER: 'Guardar usuario',
  UPDATE_PROFILE: 'Actualización del perfil',
  FLAGGED: 'Marcado',
  REPORT_DETAILS: 'Detalles del informe',
  REMOVE_FLAG: 'Remover la bandera',
  ADD_FLAG: 'Agregar bandera',
  CLIENT_REPORTS: 'Informes de clientes',
  CLIENTS: 'Clientela',
  REPORTS: 'Informes',
  CHECKS: 'Verificaciones',
  UNITS: 'Unidades',
  DELETE: 'Borrar',
  DELETED: 'Borrado exitosamente',
  UNIT_DELETE_ALERT_TITLE: '¿Está seguro de que desea eliminar esta unidad?',
  NO: 'No',
  YES: 'Sí',
  NOT_SURE: 'No estoy seguro',
  FORGET_PASSWORD_Q: '¿Contraseña olvidada?',
  RESET_PASSWORD: 'Restablecer la contraseña',
  RESET_PASSWORD_TITLE: 'Recuperar contraseña',
  RESET_PASSWORD_SUB_TITLE:
    'Ingresa tu correo electrónico para restablecer tu contraseña',
  UPDATE_PASSWORD_SUB_TITLE:
    'Ingrese su contraseña para actualizar su contraseña',
  SEND_NEW_PASSWORD: 'Envíame una nueva contraseña',
  TRY_SIGN: '¿Quieres intentar iniciar sesión nuevamente?',
  CLICK_HERE: 'Haga clic aquí',
  PLATFORM_TEMPLATE_INFO:
    'Las Plantillas de Plataforma no se pueden modificar hasta que estén conectadas a una Unidad',
  RESET_PASSWORD_INSTRUCTIONS:
    'Las instrucciones para restablecer la contraseña han sido enviadas a su correo electrónico.',
  CHECK_EMAIL_INSTRUCTIONS:
    'Revise su correo electrónico para obtener más instrucciones',
  CONTINUE_LOGIN: 'Continuar para iniciar sesión',
  TITLE: 'Título',
  REFERENCES: 'Inspecciones',
  REFERENCE: 'Inspección',
  GENERATE_REFERENCE: 'Crear nueva inspección',
  LINK: 'Enlace',
  NO_REPORTS: 'Aún no se han creado informes',
  FREE_TRIAL: 'Comience su prueba gratis hoy',
  EMAIL_VERIFICATION: 'Verifique su correo electrónico',
  SEND_EMAIL_TO: 'Hemos enviado un correo electrónico a',
  EMAIL_VERIFICATION_CONTINUE:
    'Para continuar, verifique su correo electrónico y haga clic en el enlace para verificar su cuenta.',
  NOT_RECEIVE_EMAIL: '¿No recibiste el correo electrónico?',
  RESEND_EMAIL: 'Reenvíe el correo electrónico',
  EMAIL_VERIFICATION_RESEND_ERROR: 'No se pudo enviar el correo electrónico',
  TERMS_VALIDATION: 'Debes aceptar nuestros términos y condiciones.',
  REGISTRATION_LAST_STEP: '¡Un paso más y listo!',
  KNOW_MORE_INFO: 'Por favor háganos saber el nombre de su empresa.',
  COMPANY_VALIDATION: 'El campo empresa es obligatorio',
  COMPANY_LABEL: 'Nombre de empresa',
  COMPANY: 'Compañía',
  SIGN_UP_HERE: 'Inscribirse',
  GETTING_START: 'Empezar',
  THANK_YOU: '¡Gracias!',
  ON_BOARD: 'Estamos emocionados de tenerlo a bordo.',
  UPDATE: 'Actualizar',
  SAVED: 'Guardado exitosamente',
  SENT: 'Enviado correctamente',
  UPDATE_COMPANY_HEADER: 'Detalles de la compañía',
  UPDATE_COMPANY_INFO:
    'Debes configurar el nombre de la empresa para continuar.',
  SEND_INSTRUCTIONS_EMAIL: 'Enviar instrucciones por correo electrónico',
  TOKEN_INSTRUCTION_EMAIL_TITLE:
    'Enviar correo electrónico con instrucciones para el informe',
  INSTRUCTION_EMAIL: 'Correo electrónico',
  RESET_PASSWORD_SUCCESS:
    'Gracias, si la dirección de correo electrónico que ingresó es correcta, recibirá un correo electrónico en breve con instrucciones sobre cómo restablecer su contraseña.',
  UPDATE_CLIENT: 'Detalles de la compañía',
  UPDATE_CLIENT_PAGE_TITLE:
    'Utilice esta página para actualizar los datos de su empresa.',
  UPDATE_CLIENT_CARD_TITLE: 'Detalles de la compañía',
  COMPANY_NAME_PLACEHOLDER: 'Actualizar el nombre de la empresa',
  COMPANY_NAME_LABEL: 'Nombre de empresa',
  SAVE_CLIENT: 'Guardar cliente',
  'Yes/No': 'Sí No',
  MEDIA: 'Medios de comunicación',
  TEXT: 'Texto',
  NUMBER: 'Número',
  RADIO_BUTTON: 'Opción multiple',
  CHECK_BOX: 'Casillas de verificación',
  SELECT_OPTIONS_HOLDER: 'entradas separadas con tecla de retorno',
  SELECT_OPTIONS: 'Opciones',
  SELECT_OPTIONS_HELPER:
    'Enumere las opciones en el campo de texto; use Enter para separar entre opciones',
  REGISTER_HTML_TITLE: 'Verificar primero - Regístrate',
  FILTER: 'Filtrar',
  FLAGGED_all: 'Mostrar todo',
  FLAGGED_true: 'Solo informes marcados',
  MANAGE_API_KEY_SUBHEADER: 'Administre sus claves API',
  API_KEY_MODAL_HEADER: 'Generar clave API',
  API_KEY_MODAL_SUBHEADER:
    'Por favor ingrese un nombre para identificar esta clave',
  CREATED_API_KEY_MODAL_HEADER: 'Clave creada',
  CREATED_API_KEY_MODAL_SUBHEADER:
    'Guárdelo en un lugar seguro ya que no se puede recuperar. ¡Consulte la documentación del desarrollador para aprender a usarlo!',
  NAME: 'Nombre',
  DOWNLOAD_XLSX: 'Descargar Xlsx',
  SUBSCRIBE_TITLE: 'Elige tu plan',
  SUBSCRIBE_BODY:
    'Precios simples y transparentes que crecen contigo. Prueba cualquier plan gratis durante 30 días.',
  SUBSCRIBE_BODY2: 'Precio anual (ahorre 15%)',
  SUBSCRIBE: 'Suscribir',
  PAYMENT_SUCCESS_BUTTON: 'Empezar',
  TRY_AGAIN: 'Intentar otra vez',
  PAYMENT_SUCCESS_TITLE: 'Gracias',
  PAYMENT_SUCCESS_BODY:
    'Su información se guardó exitosamente. ¡Estamos emocionados de tenerte a bordo!',
  PAYMENT_FAILURE_TITLE: 'Algo salió mal',
  PAYMENT_FAILURE_BODY:
    'Es posible que su pago haya fallado debido al saldo de su tarjeta o a otra causa. Comuníquese con el servicio de atención al cliente para obtener más aclaraciones.',
  SUBSCRIPTION_TITLE: 'Administrar Suscripciones',
  SUBSCRIPTION_DESCRIPTION: 'ADMINISTRE SU SUSCRIPCIÓN',
  SUBSCRIPTION_CARD_HEADER: 'Información de suscripción',
  SUBSCRIPTION_CARD_SUB_HEADER:
    'Gestione los detalles relacionados con sus direcciones de correo electrónico asociadas',
  SUBSCRIPTION_PLAN: 'Plan',
  SUBSCRIPTION_CARD: 'Tarjeta de crédito',
  SUBSCRIPTION_BILLED_WITH: 'Facturado con Mastercard ••••',
  MANAGE_SUBSCRIPTION: 'Administrar suscripción',
  SUBSCRIPTION_ACTIVE: 'Activo',
  SUBSCRIPTION_IN_ACTIVE: 'Inactivo',
  SUBSCRIPTION_END: 'FECHA FINAL',
  PRICING_PLANS: 'Planes de precios',
  CURRENCY: '€',
  MONTH: 'mes',
  YEAR: 'el',
  ENTERPRISE_TITLE: 'Ponerse en contacto',
  ENTERPRISE_NAME: 'Platino',
  ENTERPRISE_DESCRIPTION: 'Creado para clientes empresariales',
  ENTERPRISE_FEATURE1: 'Unidades ilimitadas',
  ENTERPRISE_FEATURE2: 'Inicio de sesión único',
  ENTERPRISE_FEATURE3: 'SLA de alta disponibilidad',
  ENTERPRISE_FEATURE4: 'Integraciones a medida',
  ENTERPRISE_FEATURE5: 'Soporte de incorporación y capacitación',
  ENTERPRISE_FEATURE6: 'Todas las características de oro',
  EDIT_ITEM: 'Actualizar',
  UPDATE_CHECKLIST_ITEM: 'Actualizar elementos de la lista de verificación',
  EDIT: 'Editar',
  N0_CLIENTS_ACTIVITIES: 'Sin actividades de clientes',
  CREATION_DATE: 'Fecha de creación',
  ACTIVITY_ACTION: 'Acción',
  ACTIVITY_MODULE: 'Módulo',
  CLIENT_NAME: 'Nombre',
  CLIENT_LABEL: 'Cliente',
  CLIENT_ACTIVITY_SEARCH_PLACEHOLDER: 'Seleccionar Cliente',
  CLIENT_ACTIVITY_TITLE: 'Actividades de clientes',
  MULTI_MEDIA: 'Multimedia',
  CONDITION: 'Condición',
  UPLOAD: 'Selecciona una imagen para subir',
  BRONZE: 'Bronce',
  GOLD: 'Oro',
  ENTERPRISE: 'Empresa',
  NONE: 'Ninguno',
  HISTORY: 'Historia',
  CHECKLIST_ITEM_DRILL_DOWN_DESCRIPTION:
    'A continuación se muestra el historial de todas las inspecciones de esta unidad. Haga clic en cada fecha para cargar el informe correspondiente.',
  REPORTS_TO_DATE: 'Informes hasta la fecha',
  FLAGGED_REPORTS: 'Informes marcados',
  ACTIVE_UNITS: 'Unidades activas',
  RECENT_REPORTS: 'Informes recientes',
  RECENT_UNITS: 'Unidades agregadas recientemente',
  INSPECTOR: 'Inspector',
  DATE: 'Fecha',
  REPORTS_COUNT: 'Informes',
  UNIT: 'Unidad',
  VIEW_ALL: 'Ver todo',
  ISSUE_PRIORITY: 'Prioridad del problema',
  ISSUE_TITLE: 'Prioridad del problema',
  ISSUE_DESCRIPTION: 'Descripción',
  ISSUE_ID: 'IDENTIFICACIÓN',
  ISSUE_REPORTED: 'Reportado',
  ISSUES: 'Marcado',
  ISSUE_GALLERY: 'Galería',
  ISSUE_DETAILS: 'Detalles del problema',
  SHARING_REPORT: 'Compartir',
  LOGGED_IN_FIRST: 'Usted debe estar conectado',
  WELCOME_MESSAGE: 'Bienvenido,',
  YOUR_STATISTICS_MESSAGE: 'Tus estadísticas de hoy,',
  ISSUES_HEADER: 'Marcado',
  ISSUES_SUBHEADER: 'Gestionar los problemas encontrados durante la inspección',
  ISSUES_PRIORITY: 'PRIORIDAD',
  UPPERCASE_TITLE: 'TÍTULO',
  STATUS: 'ESTADO',
  UPPERCASE_UNIT: 'UNIDAD',
  DESCRIPTION: 'DESCRIPCIÓN',
  IMAGES: 'IMÁGENES',
  REPORTED: 'REPORTADO',
  DEACTIVATED: 'Desactivado',
  DEACTIVATE: 'Desactivar',
  CO_SIGN: 'Co-firmar',
  CO_SIGN_HERE: 'Co-firma aquí',
  SIGN_MODAL_TITLE: 'Informe de firma conjunta',
  SIGN_MODAL_DESCRIPTION:
    'Utilice esta ventana de diálogo para firmar conjuntamente el informe de inspección.',
  NAME_HOLDER: 'Agregar nombre',
  NAME_REQUIRED: 'El campo de nombre es obligatorio.',
  SIGNATURE_IMAGE_URL: 'Firma',
  SIGNATURE_REQUIRED: 'El campo de firma es obligatorio',
  SIGN_BUTTON: 'Entregar',
  UPDATE_PASSWORD_INFO: '¿Quieres actualizar tu contraseña?',
  UPDATE_PASSWORD_LINK: 'Actualiza contraseña',
  UPDATE_USER_PASSWORD: 'Cambiar la contraseña',
  UPDATE_USER_PASSWORD_CARD_TITLE: 'informacion del usuario',
  OLD_PASSWORD_LABEL: 'Contraseña actual',
  SAVE_USER_PASSWORD: 'Ahorrar',
  OLD_PASSWORD_PLACEHOLDER: 'Contraseña actual...',
  COPY_REPORT_TOKEN: 'Copiar token de informe',
  QR_CODE: 'Código QR',
  QR_CODE_MODAL_TITLE: 'Enlace de inspección QR',
  DOWNLOAD: 'Descargar',
  DOWNLOADED: 'Descargado exitosamente',
  DEVELOPMENT: 'Desarrollo',
  UNIT_FORM_DESCRIPTION_PLACEHOLDER: 'Introduzca una descripción aquí.',
  UNIT_FORM_TITLE_PLACEHOLDER: 'Introduzca un título aquí.',
  UNIT_FORM_DESCRIPTION_CAPTION:
    'El usuario final verá esta información cuando inicie el informe, así que asegúrese de que esta descripción le ayude a crear el informe de la mejor manera para su negocio.',
  CREATE_USER_NAME_PLACEHOLDER: 'Introduzca el nombre aquí',
  CREATE_USER_EMAIL_PLACEHOLDER: 'Ingrese el correo electrónico aquí',
  SAVE: 'Ahorrar',
  CREATE: 'Crear',
  UPLOAD_CLIENT_LOGO: 'Logo',
  COPY: 'Copiar',
  ROLE: 'Role',
  ROLE_LABEL: 'Seleccionar rol de usuario',
  ROLE_client: 'Administrador de clientes',
  ROLE_client_manager: 'Gerente de clientes',
  ROLE_client_contributor: 'Colaborador del cliente',
  USERS_LIST_EDIT_USER_BUTTON_TOOLTIP: 'Actualizar usuario',
  USERS_LIST_UPDATE_USER_DIALOG_TITLE: 'Actualizar información del usuario',
  USERS_LIST_UPDATE_USER_DIALOG_CAPTION: 'Actualizar usuario',
  USERS_LIST_UPDATE_USER_DIALOG_CREATE_BUTTON: 'Actualizar usuario',
  WEBHOOK_SCREEN_LIST_TITLE: 'Ganchos web',
  WEBHOOK_SCREEN_LIST_CAPTION: 'Gestiona tus webhooks aquí',
  WEBHOOK_SCREEN_LIST_CREATE_BUTTON: 'Agregar webhook',
  REPORT_CREATED: 'Informe',
  ISSUE_CREATED: 'Asunto',
  COUNTER_SIGNATURE_CREATED: 'Contrafirma',
  TYPE: 'TIPO',
  URL: 'URL',
  MODIFIED: 'MODIFICADO',
  NO_WEBHOOKS_ADDED: 'Aún no se han agregado webhooks.',
  ACTION: 'ACCIÓN',
  EDIT_WEBHOOK: 'Editar',
  WEBHOOK_DETAILS: 'Detalles',
  WEBHOOK_SCREEN_SAVE_TITLE: 'Agregar webhook',
  WEBHOOK_SCREEN_SAVE_CAPTION:
    'Seleccione el tipo de evento sobre el que desea recibir notificaciones y luego agregue la URL de su webhook.',
  HOOK_TYPE_REQUIRED: 'El campo tipo es obligatorio',
  HOOK_URL_REQUIRED: 'El campo URL es obligatorio',
  ADD_WEBHOOK: 'Agregar webhook',
  WEBHOOK_TYPE_LABEL: 'Tipo',
  WEBHOOK_TYPE_PLACEHOLDER: 'Tipo',
  WEBHOOK_URL_PLACEHOLDER: 'URL',
  WEBHOOK_URL_LABEL: 'URL',
  WEBHOOK_SCREEN_DETAILS_TITLE: 'Ganchos web',
  WEBHOOK_SCREEN_DETAILS_CAPTION: 'Organización / Webhooks',
  HOOK_DATE: 'FECHA',
  WEBHOOK_RETRY: 'Rever',
  SUCCESS: 'Éxito',
  FAILED: 'Fallido',
  REQUEST_DETAIL_MODAL_HEADER: 'Pedir detalles',
  DETAILS: 'Detalles',
  RESPONSE: 'RESPUESTA',
  BODY: 'Cuerpo',
  ACTIONS: 'Comportamiento',
  INVALID_MAGIC_LINK: 'El enlace mágico puede no ser válido o estar caducado.',
  URL_VALIDATION: 'Por favor ingresa una URL válida',
  UNIT_FORM_REDIRECT_URL_PLACEHOLDER:
    'Introduzca aquí una URL de redireccionamiento de inspección.',
  UNIT_FORM_REDIRECT_URL_LABEL:
    'Introduzca aquí una URL de redireccionamiento de inspección.',
  ID_NAME: 'Nombre de identificación',
  CREATED_AT: 'Creado',
  OPEN: 'Abierto',
  NEW_CHECKLIST_TITLE: 'Nueva lista de verificación',
  UNTITLED_CHECKLIST: 'Lista de verificación sin título',
  UNTITLED_CHECKLIST_FROM_TEMPLATE:
    'Lista de verificación sin título (de la plantilla)',
  CREATE_CHECKLIST: 'Crear nueva lista de verificación',
  UPDATE_CHECKLIST: 'Lista de verificación de actualización',
  SAVE_CHECKLIST_SUBTITLE:
    'Guardar formulario de progreso de la lista de verificación',
  CHECKLISTS_TITLE: 'listas de verificación',
  MANAGE_TEMPLATE: 'Administrar',
  COPY_INSPECTION: 'Copiar link',
  DUPLICATE: 'Duplicar',
  DUPLICATED: 'Duplicado exitosamente',
  NEW_UNIT_TITLE: 'Nueva unidad',
  UPDATE_UNIT: 'Unidad de actualización',
  CREATE_UNIT: 'Crear nueva unidad',
  CREATE_UNIT_SUBTITLE:
    'Las unidades son los objetivos de cada inspección, como activos o ubicaciones.',
  SAVE_UNIT_SUBTITLE: 'Guardar unidad',
  NEW_BLANK_TEMPLATE: 'Nueva plantilla en blanco',
  TEMPLATE_PAGE_TITLE: 'Plantillas',
  PLATFORM_TEMPLATE_PAGE_TITLE: 'Plantillas de plataforma',
  CLIENT_TEMPLATE_PAGE_TITLE: 'Galería de plantillas',
  UPDATE_TEMPLATE: 'Plantilla de actualización',
  SAVE_TEMPLATE_SUBTITLE: 'Cambiar nombre e imagen de la plantilla',
  CREATE_TEMPLATE: 'Crear plantilla',
  SELECT_TEMPLATE: 'Seleccionar plantilla',
  TEMPLATE_BUILDER: 'Generador de plantillas',
  MANAGE_CHECKLIST_ITEMS: 'Editar elementos de la lista de verificación',
  CREATE_BLANK: 'Crear en blanco',
  CHECKLIST_DELETE_ALERT_TITLE:
    '¿Está seguro de que desea eliminar esta lista de verificación?',
  CHECKLIST: 'Lista de Verificación',
  CREATE_CATEGORY: 'Crear una categoría',
  UPDATE_CATEGORY: 'Actualizar una categoría',
  PEOPLE: 'Gente',
  SELECT: 'Seleccionar....',
  LANGUAGE_pt: 'portugués',
  LANGUAGE_en: 'Inglés',
  LANGUAGE_fr: 'Francés',
  LANGUAGE_de: 'Alemán',
  LANGUAGE_zh: 'Chino',
  LANGUAGE_es: 'Español',
  LANGUAGE_DEFAULT: 'Por defecto',
  Dashboard: 'Panel',
  Issues: 'Asuntos',
  Organisation: 'Compañía',
  USERS: 'Usuarios',
  Developer: 'Desarrollador',
  Webhooks: 'Ganchos web',
  Subscription: 'Suscripción',
  MANAGE_UNITS: 'Administra tus unidades',
  ID: 'IDENTIFICACIÓN',
  LAST_REPORT_DATE: 'Último informe',
  DELETE_WARNING: 'No podrás revertir después de la eliminación',
  DELETE_CLIENT_WARNING: '¿Realmente quieres eliminar este cliente?',
  CREATE_CLIENT: 'Crear nuevo cliente',
  CREATE_CLIENT_DESCRIPTION:
    'Utilice esta ventana de diálogo para agregar un nuevo cliente',
  EMAIL: 'Correo electrónico',
  IMAGE: 'Imagen',
  INVALID_FILE_TYPE: 'No puedes cargar estos tipos de archivos.',
  CLIENT_PASSWORD_INFO: 'Utilice esta contraseña para iniciar sesión',
  CREATE_CLIENT_BUTTON: 'Crear cliente',
  SIGN_OUT: 'desconectar',
  DEVELOPER_DOCUMENTATION: 'Documentación del desarrollador',
  OF: 'de',
  PAGINATION_INFO: 'Filas por página',
  CANCEL: 'Cancelar',
  Client: 'Asignado a',
  Client_here: 'No asignado',
  New_Category: 'Nueva categoría',
  EDIT_CHECKLIST_ITEM_Title: 'Pregunta',
  CREATE_INSTRUCTION: 'Agregar instrucciones',
  UPDATE_INSTRUCTION: 'Editar instrucciones',
  SAVE_INSTRUCTION_SUBTITLE:
    'La imagen y la descripción de la instrucción serán visibles junto a la pregunta para guiar al usuario sobre cómo proporcionar una respuesta',
  ADD_ITEM: '+ Agregar',
  TYPE_OF_RESPONSE: 'Tipo de respuesta',
  Image: 'Imagen',
  EDIT_Instructions: 'Editar Instrucciones',
  ASSIGN_OWNER: 'Asignar Propietario',
  ASSIGN_OWNER_TO: 'Asignar propietario a',
  REMOVE_OWNER: 'Eliminar propietario',
  OWNER_REMOVED: 'Propietario eliminado',
  ASSIGNED_OWNER: 'Propietario Asignado',
  SHARE_CHECK: 'Compartir revisión',
  SHARE_CHECK_DESCRIPTION:
    'Utiliza el formulario a continuación para compartirla con el verificador.',
  TEMPLATE_BUILDER_BREADCRUMB: 'Plantillas',
  SEND_VIA: 'Enviar a través de',
  To: 'A',
  Subject: 'Asunto',
  EMAIL_TOKEN_DESCRIPTION: 'Descripción',
  Share_Inspection: 'Compartir Inspección',
  Open_Modal: 'Nueva revisión',
  CHECKFIRST: 'Checkfirst',
  Select_unit: 'Seleccionar unidad',
  SELECT_CHECKLIST: 'Seleccionar lista de verificación',
  Checklists: 'Listas de verificación',
  Templates: 'Plantillas',
  Email: 'Correo electrónico',
  Add: 'Agregar',
  Back: 'Volver',
  Owner: 'Propietario',
  Description: 'Descripción',
  Created: 'Creado',
  UPGRADE: 'Actualizar',
  READY_FOR_AN_UPGRADE: '¿Listo para una actualización?',
  UPGRADE_ACCOUNT_TO_ACCESS_THEM:
    'Estos informes están bloqueados debido a límites de uso en su plan. Por favor,' +
    '\n' +
    'actualice su cuenta para acceder a ellos.',
  ADD_LOGIC: 'Agregar lógica',
  EDIT_CHECKLIST_ITEM: 'Instrucciones',
  VERIFY_WITH_AI: 'Verificar con IA',
  ADD_INSTRUCTIONS: 'Agregar instrucciones',
  ADD_IMAGE: 'Agregar imagen',
  CHECKLIST_BUILDER: {
    TITLE_PLACEHOLDER: 'Título',
    DESCRIPTION_PLACEHOLDER: 'Agregar descripción (opcional)',
    ADD_CRITERIA_BUTTON_LABEL: 'Agregar criterios',
    ADD_SECTION_BUTTON_LABEL: 'Agregar sección',
    SAVED_MESSAGE: 'Guardado exitosamente',
    BACK_BUTTON: 'Atrás',
    NEW_CHECK_BUTTON: 'Nuevo cheque',
    PREVIEW_BUTTON: 'Modo de vista previa',
    USE_TEMPLATE_BUTTON: 'Usar plantilla',
    FLOATING_TOOLBAR: {
      DUPLICATE: 'Duplicar sección',
      DELETE: 'Eliminar sección',
      ADD_CRITERIA: 'Agregar criterio',
      ADD_SECTION: 'Agregar sección'
    },
    SECTION: {
      TITLE_PLACEHOLDER: 'Escribe el título de la sección aquí.',
      DELETE_CONFIRMATION_MESSAGE:
        'Esto también eliminará cualquier elemento de esta sección.',
      DELETE_TOOLTIP: 'Borrar',
      ERRORS: {
        REQUIRED: 'El campo del título es obligatorio.',
        DUPLICATE:
          'El título no debe ser uno de los siguientes valores: {{sections}}'
      }
    },
    CRITERIA: {
      TITLE_PLACEHOLDER: 'Agregar criterios',
      TYPE_PLACEHOLDER: 'Tipo de respuesta',
      DELETE_CONFIRMATION_MESSAGE:
        '¿Está seguro de que desea eliminar este criterio?',
      REQUIRED_LABEL: 'Requerido',
      CHANGE_TYPE_CONFIRMATION_MESSAGE:
        'Este criterio contiene criterios lógicos y anidados. Esto se perderá si cambia el tipo de criterio. ¿Le gustaría cambiar el tipo de criterio?',
      ERRORS: {
        REQUIRED: 'El campo del título es obligatorio.',
        MIN: 'Por favor ingrese al menos 2 opciones.',
        MAX: 'Ingrese menos de 100 opciones.'
      }
    },
    LOGIC: {
      DAMAGED: 'Dañado',
      POOR: 'Pobre',
      FAIR: 'Justo',
      GOOD: 'Bueno',
      GREAT: 'Excelente',
      IF: 'Si la respuesta',
      IF_IMAGE: 'Si imagen',
      THEN: 'Entonces',
      YES: 'Sí',
      NO: 'No',
      NA: 'N/D',
      IS: 'Es',
      IS_NOT: 'No es',
      IS_EMPTY: 'Está vacío',
      IS_NOT_EMPTY: 'No esta vacío',
      ADD_CRITERIA: 'Mostrar criterios',
      FLAG_REPORT: 'Marcar el informe',
      RAISE_ISSUE: 'Plantear un problema',
      EQUALS: 'igual (=)',
      DOES_NOT_EQUAL: 'No es igual (≠)',
      GREATER_THAN: 'Mayor que (>)',
      LESS_THAN: 'Menor que (<)',
      GREATER_THAN_OR_EQUAL_TO: 'Mayor o igual a (≥)',
      LESS_THAN_OR_EQUAL_TO: 'Menor o igual a (≤)',
      BETWEEN: 'Entre',
      NOT_BETWEEN: 'No entre',
      CONTAINS: 'Contiene',
      DOES_NOT_CONTAIN: 'No contiene',
      STARTS_WITH: 'Comienza con',
      ACTION_PLACEHOLDER: 'Seleccione la acción',
      YES_NO_PLACEHOLDER: 'Seleccionar múltiples valores',
      DELETE_CONFIRMATION_MESSAGE:
        '¿Estás seguro de que quieres eliminar esta lógica?'
    },
    INSTRUCTIONS: {
      PLACEHOLDER: 'Texto...',
      LABEL: 'Instrucciones',
      IMAGE_LABEL: 'Imagen'
    }
  },
  SaveBasicInformation: {
    ERRORS: {
      TITLE_REQUIRED: 'El campo de nombre del dispositivo es obligatorio.',
      CLIENT_FIELD_REQUIRED: 'El campo de cliente es obligatorio.'
    }
  },
  SHARE_INSPECTION_MODAL: {
    STEP_1: {
      TITLE: 'Seleccionar lista de verificación',
      SUBTITLE:
        'Seleccione lo que desea revisar de la lista de listas de verificación a continuación.',
      NAME: 'Nombre',
      CREATED: 'Creado',
      NEW_CHECKLIST: 'Nueva lista de verificación'
    },
    STEP_2: {
      TITLE: 'Seleccionar unidad',
      SUBTITLE: 'Seleccione la unidad que desea que se revise.',
      NAME: 'Nombre',
      OWNER: 'Propietario',
      CREATED: 'Creado',
      NEW_UNIT: 'Nueva unidad'
    },
    STEP_3: {
      TITLE: '¡Éxito!',
      SUBTITLE:
        'El chequeo {{id}} se ha creado correctamente. Utiliza el formulario a continuación para compartirlo con el verificador.',
      SEND_VIA: 'Enviar vía',
      EMAIL: 'Correo electrónico',
      LINK: 'Enlace',
      QR: 'Código QR',
      COPY: 'Copiar',
      CANCEL: 'Cancelar'
    }
  },
  CHECKS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Vista previa',
      SHARE: 'Compartir'
    },
    HEADERS: {
      ID: 'ID',
      CHECKLIST: 'Lista de verificación',
      UNIT: 'Unidad',
      SCHEDULED: 'Programado',
      DUE: 'Vencimiento',
      DUE_DATE: 'Fecha de vencimiento',
      ASSIGNED: 'Asignado',
      ASSIGNED_TO: 'Asignado a',
      STATUS: 'Estado'
    },
    STATUS: {
      OPEN: 'Abierto',
      STARTED: 'Iniciado',
      COMPLETED: 'Completado',
      SCHEDULED: 'Programado',
      CLOSED: 'Cerrado'
    }
  },
  LOGO_ALT: 'Logo',
  CHECKLIST_ITEM_TYPE: {
    'Yes/No': 'Sí/No',
    MEDIA: 'Medios',
    TEXT: 'Texto',
    NUMBER: 'Número',
    RADIO_BUTTON: 'Botones de radio',
    CHECK_BOX: 'Casillas de verificación',
    MULTIPLE_CHOICE: 'Selección múltiple',
    RESPONSE_TYPES: 'Tipos de respuesta',
    CUSTOM_OPTIONS: 'Opciones personalizadas',
    MULTI_MEDIA: 'Medios',
    AI: 'IA',
    CONDITION: 'Condición',
    MULTIPLE_CHOICE_OPTIONS: {
      YES: 'Sí',
      NO: 'No',
      NA: 'N/A',
      GOOD: 'Bueno',
      FAIR: 'Regular',
      POOR: 'Pobre',
      CONFORM: 'Conforme',
      NOT_CONFORM: 'No conforme',
      PENDING: 'Pendiente',
      PLACEHOLDER: 'Título de la opción'
    },
    MULTIPLE_CHOICE_MODAL: {
      TITLE: 'Opciones de selección múltiple',
      SUBTITLE: 'por ejemplo: Sí, No, N/A',
      OPTION: 'Opción',
      DELETE: 'Eliminar',
      ADD_OPTION_BUTTON: 'Agregar opción (o presione Enter)',
      SAVE_BUTTON: 'Guardar',
      CANCEL_BUTTON: 'Cancelar',
      MULTIPLE_SELECTION_TOGGLE: 'Selección múltiple',
      SCORING_TOGGLE: 'Puntuación',
      SCORE: 'Puntuación'
    }
  },

  REPORTS_PAGE: {
    BUTTONS: {
      PREVIEW: 'Previsualización',
      SHARE: 'Compartir'
    },
    HEADERS: {
      IMAGE_URL: 'URL de la imagen',
      RULE: 'Regla',
      IMAGE_CAPTION: 'Leyenda de la imagen',
      WORDS_USED_TO_CHECK: 'Palabras usadas para verificar',
      DECISION: 'Decisión',
      VERIFIED: 'Verificado',
      VERIFICATION_PROMPT: 'Mensaje de verificación',
      ASSIGNED: 'Asignado',
      ASSIGNED_TO: 'Asignado a',
      STATUS: 'Estado'
    },
    STATUS: {
      OPEN: 'Abierto',
      STARTED: 'Iniciado',
      COMPLETED: 'Completado',
      SCHEDULED: 'Programado',
      CLOSED: 'Cerrado'
    }
  },

  SHOW_MORE: '▼ Mostrar más',
  SHOW_LESS: '▲ Mostrar menos',
  CONDITION_RESPONSES: {
    DAMAGED: 'Dañado',
    POOR: 'Pobre',
    FAIR: 'Justo',
    GOOD: 'Bueno',
    GREAT: 'Excelente'
  },
  SHOW_DETAILS: 'Mostrar detalles',
  CHECKLISTS: {
    NAME: 'Nombre',
    CREATED_BY: 'Creado por',
    LAST_MODIFIED: 'Última modificación',
    NEW_CHECKLIST: 'Nueva lista de verificación'
  },
  NO_CHECKS_CREATED: 'No se han creado verificaciones',
  NO_REPORTS_CREATED: 'No se han creado informes',
  NO_CHECKLIST_CREATED: 'No se han añadido listas de verificación a esta unidad'
};

export default esJSON;

import { makeObservable, observable } from 'mobx';
import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut
} from 'src/axios/axiosUtils';
import { units as unitsReq } from 'src/axios/requests';
import { LoadingState } from 'src/axios/types';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import type {
  CategoryDto,
  GetBasicInformationDto,
  Reorder,
  SaveBasicInformationDto,
  UnitDetails,
  UnitMetaResponse
} from './types';

export class UnitStore extends BaseBackendStore {
  @observable unit: UnitDetails;

  @observable unitsResponse: UnitMetaResponse = {
    data: [],
    meta: {
      itemCount: 0
    }
  };

  @observable unitsState: LoadingState = LoadingState.IDLE;

  @observable unitState: LoadingState = LoadingState.IDLE;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
  }

  async getUnits(params?: string) {
    try {
      this.unitsState = LoadingState.LOADING;
      let url = unitsReq;
      if (params) {
        url += `?${params}`;
      }
      this.unitsResponse = await httpGet(url, false);
      console.log(
        'getUnits unitsResponse ' + JSON.stringify(this.unitsResponse)
      );
      this.unitsState = LoadingState.DONE;
    } catch {
      this.unitsState = LoadingState.FAILED;
    }
  }

  async getUnit(unitId: number) {
    try {
      this.unitState = LoadingState.LOADING;
      this.unit = await httpGet(`${unitsReq}/${unitId}`, false, {
        headers: {
          'request-version': '2'
        }
      });
      this.unitState = LoadingState.DONE;
    } catch {
      this.unitState = LoadingState.FAILED;
    }
  }

  setUnit(unitToSet: UnitDetails) {
    this.unit = unitToSet;
  }

  async reorderChecklistItems(
    unitId: number,
    categoryId: number,
    checklistItemsOrders: Reorder[]
  ) {
    await httpPatch(
      `${unitsReq}/${unitId}/categories/${categoryId}/checklist-items`,
      checklistItemsOrders
    );
  }

  async createUnit(unit: SaveBasicInformationDto) {
    await httpPost(`${unitsReq}`, unit);
  }

  updateUnitBasicInformation({
    client,
    description,
    imageURL,
    title,
    user,
    redirectURL
  }: SaveBasicInformationDto) {
    this.unit = {
      ...this.unit,
      title,
      description,
      imageURL,
      client,
      user,
      redirectURL
    };
  }

  get unitBasicInformationDto(): GetBasicInformationDto {
    return {
      client: {
        id: this.unit.client.id
      },
      description: this.unit.description,
      redirectURL: this.unit.redirectURL,
      imageURL: this.unit.imageURL,
      title: this.unit.title,

      ...(this.unit.user && {
        user: {
          id: this.unit?.user?.id
        }
      })
    };
  }

  resetUnit() {
    this.unit = undefined;
  }
}

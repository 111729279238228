import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  ChecklistItem,
  ChecklistItemType,
  ViewChecklistItem
} from 'src/models/unit';
import {
  getChecklistItemAllowedTypes,
  getType,
  isCheckBox,
  numberOfWrappedItems,
  toggleHiddenChips
} from '../../utils';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as S from './styled';
import { useEffect, useRef, useState } from 'react';
import { MULTIPLE_CHOICE_TYPES, OPTION_SETS } from './constants';
import { FeatureStatus } from 'src/models/feature.toggle';
import { MultipleChoiceModal } from '../MultipleChoiceModal';
import { observer } from 'mobx-react';
import { useElementDimensions } from 'src/hooks/useElementDimensions';

interface Props {
  isReadOnly: boolean;
  checklistItemState: ViewChecklistItem;
  categoryId: number;
  onTransitionEnd: () => void;
}

export const TypeDropdown = observer(
  ({ isReadOnly, categoryId, checklistItemState, onTransitionEnd }: Props) => {
    const { t } = useTranslation();
    const [openMultipleChoiceModal, setOpenMultipleChoiceModal] = useState({
      open: false,
      newOptions: false
    });
    const [nOfHiddenChips, setNOfHiddenChips] = useState(0);
    const { values, setFieldValue, handleChange } = useFormikContext() as any;
    const dropdownRef = useRef();
    const chipsRefs = useRef<any[]>([]);
    const wrappedItemsCountDropdown = numberOfWrappedItems(dropdownRef.current);
    const showMultipleChoice =
      process.env.REACT_APP_MULTIPLE_CHOICE === FeatureStatus.ENABLED;

    const { height: textAreaHeight } = useElementDimensions(
      `criteria-container-${checklistItemState.id}`
    );
    const { width: dropdownWidth } = useElementDimensions(
      `type-dropdown-${checklistItemState.id}`
    );

    useEffect(() => {
      const hiddenItemsCount = toggleHiddenChips(chipsRefs, textAreaHeight);
      setNOfHiddenChips(hiddenItemsCount);
    }, [textAreaHeight, dropdownWidth, chipsRefs, values?.selectOptions]);

    return (
      <>
        <S.StyledMuiSelectField
          fullWidth
          name="type"
          placeholder={t('CHECKLIST_BUILDER.CRITERIA.TYPE_PLACEHOLDER')}
          onChange={(value) => {
            if (
              isCheckBox({
                type: value.target?.value,
                allowMultipleOptions: checklistItemState.allowMultipleOptions
              } as ViewChecklistItem)
            ) {
              setFieldValue('mandatory', false);
            }
          }}
          handleChange={(e) => {
            const value: string = e.target.value;
            const existOptions = [
              `option-set-0`,
              `option-set-1`,
              `option-set-2`
            ];
            if (value === 'custom-options') {
              setOpenMultipleChoiceModal({
                open: true,
                newOptions: true
              });
              return;
            }
            if (existOptions.includes(value)) {
              const index = value.split('option-set-')[1];
              const options = [
                //need to delete all old options first
                ...values.selectOptions.map((e) => ({ ...e, isDeleted: true })),
                ...OPTION_SETS[index].map((e) => ({
                  ...e,
                  title: t(
                    `CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_OPTIONS.${e.title}`
                  )
                }))
              ];
              setFieldValue('selectOptions', options);
              setFieldValue('type', ChecklistItemType.MULTIPLE_CHOICE);
            } else if (value === 'option-set-selected') {
              setOpenMultipleChoiceModal({
                open: true,
                newOptions: false
              });
            } else {
              handleChange(e);
            }
          }}
          onTransitionEnd={onTransitionEnd}
          value={values.type}
          select
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: S.StyledMenu
              }
            },
            renderValue: (selected: string) => {
              if (
                selected == ChecklistItemType.MULTIPLE_CHOICE &&
                showMultipleChoice
              )
                return (
                  <S.FieldContainer>
                    <S.SelectedOptionsContainer
                      maxHeight={textAreaHeight + 4}
                      id={`type-dropdown-${checklistItemState.id}`}
                    >
                      {values.selectOptions
                        ?.filter((e) => !e.isDeleted)
                        .map((e, i) => (
                          <S.StyledChip
                            key={e.title}
                            label={`${e.title}`}
                            variant="outlined"
                            ref={(el) => (chipsRefs.current[i] = el)}
                            className="field-chip"
                          ></S.StyledChip>
                        ))}
                    </S.SelectedOptionsContainer>
                    {nOfHiddenChips > 0 && (
                      <span className="hidden-items">+{nOfHiddenChips} </span>
                    )}
                  </S.FieldContainer>
                );
              else {
                return getType(
                  { type: selected as ChecklistItemType, t },
                  true
                );
              }
            }
          }}
          disabled={isReadOnly}
        >
          {showMultipleChoice && MULTIPLE_CHOICE_TYPES.includes(values.type) && (
            <S.StyledChipsMenuItem
              key={`option-set-selected`}
              selected
              className="Mui-selected"
              value="option-set-selected"
            >
              <S.SelectedOptionsInsideMenu ref={dropdownRef}>
                {values.selectOptions
                  ?.filter((e) => !e.isDeleted)
                  .map((e) => (
                    <S.StyledChip
                      key={e.title}
                      label={`${e.title}`}
                      variant="outlined"
                    ></S.StyledChip>
                  ))}
              </S.SelectedOptionsInsideMenu>
              <Box>
                {wrappedItemsCountDropdown > 0 && (
                  <span>+{wrappedItemsCountDropdown} </span>
                )}
                <IconButton disabled>
                  <EditOutlinedIcon />
                </IconButton>
              </Box>
            </S.StyledChipsMenuItem>
          )}
          {showMultipleChoice && (
            <S.SubtitleMenuItem
              key="multiple-choice"
              disabled
              value="multiple-choice"
            >
              {t('CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE')}
            </S.SubtitleMenuItem>
          )}
          {showMultipleChoice &&
            OPTION_SETS.map((e, i) => (
              <S.StyledMenuItem
                key={`option-set-${i}`}
                value={`option-set-${i}`}
              >
                {e.map((o) => (
                  <S.StyledChip
                    key={o.title}
                    label={t(
                      `CHECKLIST_ITEM_TYPE.MULTIPLE_CHOICE_OPTIONS.${o.title}`
                    )}
                    variant="outlined"
                  ></S.StyledChip>
                ))}
              </S.StyledMenuItem>
            ))}
          {showMultipleChoice && (
            <S.StyledMenuItem key="custom-options" value="custom-options">
              <AddIcon fontSize="small" />
              {t('CHECKLIST_ITEM_TYPE.CUSTOM_OPTIONS')}
            </S.StyledMenuItem>
          )}
          {showMultipleChoice && (
            <>
              <S.StyledDivider />
              <S.SubtitleMenuItem
                key="response-types"
                disabled
                value="response-types"
              >
                {t(`CHECKLIST_ITEM_TYPE.RESPONSE_TYPES`)}
              </S.SubtitleMenuItem>
            </>
          )}
          {getChecklistItemAllowedTypes(values.type).map((item) => (
            <S.StyledMenuItem key={item} value={item}>
              {getType({ type: item, t })}
            </S.StyledMenuItem>
          ))}
        </S.StyledMuiSelectField>
        {openMultipleChoiceModal.open && (
          <MultipleChoiceModal
            open={openMultipleChoiceModal.open}
            checklistItemState={checklistItemState}
            useNewOptions={openMultipleChoiceModal.newOptions}
            categoryId={categoryId}
            onClose={() =>
              setOpenMultipleChoiceModal({
                open: false,
                newOptions: false
              })
            }
          />
        )}
      </>
    );
  }
);

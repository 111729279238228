import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { FlaggedOption } from '../models/unit';
import { useReports } from '../hooks/useReports';

export const DashboardInterceptor: FC = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { getReports, reportState, getFreeReportsCount } = useReports();

  useEffect(() => {
    getReports(`/reports`, null, FlaggedOption.all, 10);
    getFreeReportsCount();

    const forceRefresh = localStorage.getItem('FORCE_REFRESH_TOKEN');
    if (forceRefresh && auth.user) {
      auth.refreshUserToken().then(() => {
        localStorage.removeItem('FORCE_REFRESH_TOKEN');
        navigate(location.pathname);
      });
    }
  }, [location]);
  if (children) {
    return <>{children}</>;
  }
  return <Outlet />;
};

const znJSON = {
  AUTH_SIGN_UP_CARD_TITLE: '报名',
  AUTH_SIGN_UP_CARD_SUB_TITLE: '填写以下字段以注册帐户。',
  AUTH_SIGN_UP_CARD_GOOGLE_BUTTON: '与谷歌注册',
  AUTH_SIGN_UP_CARD_OR_SEPARATOR: '或者',
  AUTH_SIGN_UP_CARD_NAME_FIELD_LABEL: '姓名',
  AUTH_SIGN_UP_CARD_NAME_FIELD_ERROR: '名字的字段是必需的',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_LABEL: '电子邮件',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_REQUIRED: '电子邮件字段为必填项',
  AUTH_SIGN_UP_CARD_EMAIL_FIELD_ERROR_INVALID:
    '提供的电子邮件应该是有效的电子邮件地址',
  AUTH_SIGN_UP_CARD_PASS_FIELD_LABEL: '密码',
  AUTH_SIGN_UP_CARD_PASS_FIELD_ERROR: '密码字段为必填项',
  AUTH_SIGN_UP_CARD_TERMS_AGREE_STATEMENT: '我接受',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS: '条款和条件',
  AUTH_SIGN_UP_CARD_TERMS_AND_CONDITIONS_ERROR: '您必须同意我们的条款和条件',
  AUTH_SIGN_UP_CARD_BUTTON: '报名',
  AUTH_SIGN_UP_CARD_HAVE_ACCOUNT: '已经有帐户？',
  AUTH_SIGN_UP_CARD_LOGIN_LINK: '在这里登录',
  AUTH_SIGN_UP_SIDEBAR_TITLE: '创建数字检验报告',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_1: '检验管理仪表板',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_2: '易于使用的检查应用程序',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_3: '移动优先的数字检验报告',
  AUTH_SIGN_UP_SIDEBAR_USE_CASE_4: '每月尝试 10 份免费报告',
  AUTH_LOGIN_CARD_EMAIL_FIELD_LABEL: '电子邮件',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_REQUIRED: '电子邮件字段为必填项',
  AUTH_LOGIN_CARD_EMAIL_FIELD_ERROR_INVALID:
    '提供的电子邮件应该是有效的电子邮件地址',
  AUTH_LOGIN_CARD_PASS_FIELD_LABEL: '密码',
  AUTH_LOGIN_CARD_PASS_FIELD_ERROR: '密码字段为必填项',
  AUTH_LOGIN_CARD_GOOGLE_BUTTON: '使用 Google 登录',
  AUTH_LOGIN_CARD_BUTTON: '登入',
  AUTH_LOGIN_CARD_FORGOT_PASSWORD: '忘记密码？',
  AUTH_LOGIN_CARD_RESET_PASSWORD_LINK: '重设密码',
  AUTH_LOGIN_CARD_NO_ACCOUNT: '没有帐户？',
  AUTH_LOGIN_CARD_REGISTER_LINK: '登记',
  AUTH_LOGIN_CARD_ERROR_INVALID_CREDENTIALS: '错误的邮箱帐号或密码',
  USERS_LIST_PAGE_HEADER_TITLE: '用户',
  USERS_LIST_PAGE_HEADER_CAPTION: '管理您的用户',
  USERS_LIST_EMPTY_MESSAGE: '没有用户',
  USERS_LIST_PAGE_HEADER_CREATE_BUTTON: '创建新用户',
  USERS_LIST_DELETE_USER_CONFIRMATION_MESSAGE: '您确实要删除该用户吗？',
  USERS_LIST_DELETE_USER_BUTTON_TOOLTIP: '删除',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_TITLE: '用户创建',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_PASSWORD:
    '用户已创建并分配了密码：',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL:
    '还向用户发送了邀请电子邮件，电子邮件地址为',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_BODY_EMAIL_EXTENDED:
    '，他们可以使用电子邮件中的链接设置自己的密码。',
  USERS_LIST_CREATE_USER_PASSWORD_DIALOG_CAPTION: '使用此密码登录',
  USERS_LIST_CREATE_USER_DIALOG_TITLE: '创建新用户',
  USERS_LIST_CREATE_USER_DIALOG_CAPTION: '添加新用户',
  USERS_LIST_CREATE_USER_DIALOG_CREATE_BUTTON: '创建用户',
  REPORT_DETAILS_HEADER_TITLE: '报告详情',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: '下载PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: '下载XLSX',
  REPORT_DETAILS_NO_ANSWER: '没有答案',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: '问责制',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE: '此部分显示谁执行了报告',
  REPORT_VERIFICATION_VERIFICATION_TITLE: '确认',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE: ' 所有数据均通过 Checkfirst 验证',
  REPORT_HEADER_UNIT_ID_PREFIX: ' 单元',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' 检查',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_TITLE: '你确定吗？',
  EDIT_UNIT_DELETE_CATEGORY_CONFIRMATION_SUBTITLE:
    '这也将删除该类别中的所有项目。',
  COPIED: '复制成功...',
  NO_ACTIVITY_MESSAGE: '活动详情尚未公布...',
  COMMENT: '评论',
  configurations: '配置',
  new_configuration: '新配置',
  Key: '钥匙',
  Title: '标题',
  Actions: '行动',
  Clients: '客户',
  Value: '价值',
  Edit: '编辑',
  Cancel: '取消',
  Save: '节省',
  Saved: '已保存',
  OPTIONS: '选项',
  FORGET_PASSWORD: '忘记密码',
  UPDATE_PASSWORD_TITLE: '更新密码',
  CONFIRM_PASSWORD_VALIDATION: '请在密码和确认密码字段中输入相同的密码',
  PASSWORD_VALIDATION: '密码字段为必填项',
  TITLE_VALIDATION: '标题字段为必填项',
  PASSWORD_UPDATED: '密码更新成功，请尝试登录',
  ERROR: '出了些问题',
  PASSWORD: '密码',
  CONFIRM_PASSWORD: '确认密码',
  UPDATE_PASSWORD: '更新密码',
  UPDATE_USER: '更新用户',
  INVALID_EMAIL: '不合规电邮',
  UPDATE_PASSWORD_TOGGEL: '更新密码',
  NAME_VALIDATION: '名字的字段是必需的',
  EMAIL_VALIDATION: '电子邮件字段为必填项',
  MESSAGE_VALIDATION: '消息字段为必填项',
  SHOULD_VALID_EMAIL: '提供的电子邮件应该是有效的电子邮件地址',
  IMAGE_VALIDATION: '图片字段为必填项',
  NAME_PLACEHOLDER: '姓名...',
  NAME_LABEL: '姓名',
  EMAIL_PLACEHOLDER: '电子邮件...',
  EMAIL_LABEL: '电子邮件',
  MESSAGE_LABEL: '信息',
  CLIENT_IMAGE_LABEL: '图像',
  PASSWORD_PLACEHOLDER: '密码...',
  PASSWORD_LABEL: '密码',
  CONFIRM_PASSWORD_PLACEHOLDER: '确认密码...',
  CONFIRM_PASSWORD_LABEL: '确认密码',
  SAVE_USER: '保存用户',
  UPDATE_PROFILE: '更新个人信息',
  FLAGGED: '已标记',
  REPORT_DETAILS: '报告详情',
  REMOVE_FLAG: '删除标志',
  ADD_FLAG: '添加标志',
  CLIENT_REPORTS: '客户报告',
  CLIENTS: '客户',
  REPORTS: '报告',
  CHECKS: '检查',
  UNITS: '单位',
  DELETE: '删除',
  DELETED: '删除成功',
  UNIT_DELETE_ALERT_TITLE: '您确定要删除该单位吗？',
  NO: '不',
  YES: '是的',
  NOT_SURE: '没有把握',
  FORGET_PASSWORD_Q: '忘记密码？',
  RESET_PASSWORD: '重设密码',
  RESET_PASSWORD_TITLE: '恢复密码',
  RESET_PASSWORD_SUB_TITLE: '输入您的电子邮件以重置您的密码',
  UPDATE_PASSWORD_SUB_TITLE: '输入您的密码以更新您的密码',
  SEND_NEW_PASSWORD: '给我发送一个新密码',
  TRY_SIGN: '想要尝试再次登录吗？',
  CLICK_HERE: '点击这里',
  PLATFORM_TEMPLATE_INFO: '平台模板在连接到单元之前无法修改',
  RESET_PASSWORD_INSTRUCTIONS: '密码重置说明已发送至您的电子邮件',
  CHECK_EMAIL_INSTRUCTIONS: '检查您的电子邮件以获取更多说明',
  CONTINUE_LOGIN: '继续登录',
  TITLE: '标题',
  REFERENCES: '检查',
  REFERENCE: '检查',
  GENERATE_REFERENCE: '创建新的检查',
  LINK: '关联',
  NO_REPORTS: '尚未创建报告',
  FREE_TRIAL: '开始免费试用',
  EMAIL_VERIFICATION: '验证您的电子邮件',
  SEND_EMAIL_TO: '我们寄了封电子邮件给',
  EMAIL_VERIFICATION_CONTINUE:
    '要继续，请检查您的电子邮件并单击链接以验证您的帐户。',
  NOT_RECEIVE_EMAIL: '没有收到电子邮件？',
  RESEND_EMAIL: '重新发送电子邮件',
  EMAIL_VERIFICATION_RESEND_ERROR: '发送邮件失败',
  TERMS_VALIDATION: '您必须同意我们的条款和条件',
  REGISTRATION_LAST_STEP: '再多一步就完成了！',
  KNOW_MORE_INFO: '请告诉我们您的公司名称',
  COMPANY_VALIDATION: '公司字段为必填项',
  COMPANY_LABEL: '公司名称',
  COMPANY: '公司',
  SIGN_UP_HERE: '报名',
  GETTING_START: '开始使用',
  THANK_YOU: '谢谢你！',
  ON_BOARD: '我们很高兴您加入。',
  UPDATE: '更新',
  SAVED: '保存成功',
  SENT: '发送成功',
  UPDATE_COMPANY_HEADER: '公司详情',
  UPDATE_COMPANY_INFO: '您应该设置公司名称才能继续',
  SEND_INSTRUCTIONS_EMAIL: '发送说明电子邮件',
  TOKEN_INSTRUCTION_EMAIL_TITLE: '发送报告说明电子邮件',
  INSTRUCTION_EMAIL: '电子邮件',
  RESET_PASSWORD_SUCCESS:
    '谢谢，如果您输入的电子邮件地址正确，您很快就会收到一封电子邮件，其中包含有关如何重置密码的说明',
  UPDATE_CLIENT: '公司详情',
  UPDATE_CLIENT_PAGE_TITLE: '使用此页面更新您的公司数据。',
  UPDATE_CLIENT_CARD_TITLE: '公司详情',
  COMPANY_NAME_PLACEHOLDER: '更新公司名称',
  COMPANY_NAME_LABEL: '公司名称',
  SAVE_CLIENT: '保存客户端',
  'Yes/No': '是/否',
  MEDIA: '媒体',
  TEXT: '文本',
  NUMBER: '数字',
  RADIO_BUTTON: '多项选择',
  CHECK_BOX: '复选框',
  SELECT_OPTIONS_HOLDER: '带回车键的单独条目',
  SELECT_OPTIONS: '选项',
  SELECT_OPTIONS_HELPER: '请在文本字段中列出选项；使用 Enter 分隔选项',
  REGISTER_HTML_TITLE: '首先检查 - 注册',
  FILTER: '筛选',
  FLAGGED_all: '显示所有',
  FLAGGED_true: '仅标记报告',
  MANAGE_API_KEY_SUBHEADER: '管理您的 API 密钥',
  API_KEY_MODAL_HEADER: '生成API密钥',
  API_KEY_MODAL_SUBHEADER: '请输入一个名称来识别该密钥',
  CREATED_API_KEY_MODAL_HEADER: '已创建密钥',
  CREATED_API_KEY_MODAL_SUBHEADER:
    '请将其存放在安全的地方，因为它无法恢复。查看开发者文档以了解如何使用它！',
  NAME: '姓名',
  DOWNLOAD_XLSX: '下载 Xlsx',
  SUBSCRIBE_TITLE: '选择您的计划',
  SUBSCRIBE_BODY: '简单、透明的定价，与您一起成长。免费试用任何计划 30 天。',
  SUBSCRIBE_BODY2: '按年定价（节省 15%）',
  SUBSCRIBE: '订阅',
  PAYMENT_SUCCESS_BUTTON: '开始使用',
  TRY_AGAIN: '再试一次',
  PAYMENT_SUCCESS_TITLE: '谢谢',
  PAYMENT_SUCCESS_BODY: '您的信息已成功保存。我们很高兴您加入！',
  PAYMENT_FAILURE_TITLE: '出了些问题',
  PAYMENT_FAILURE_BODY:
    '您的支付可能因您的卡余额或其他原因而失败，请联系客服以获取更多说明',
  SUBSCRIPTION_TITLE: '管理订阅',
  SUBSCRIPTION_DESCRIPTION: '管理您的订阅',
  SUBSCRIPTION_CARD_HEADER: '订阅信息',
  SUBSCRIPTION_CARD_SUB_HEADER: '管理与您关联的电子邮件地址相关的详细信息',
  SUBSCRIPTION_PLAN: '计划',
  SUBSCRIPTION_CARD: '信用卡',
  SUBSCRIPTION_BILLED_WITH: '使用万事达卡支付 ••••',
  MANAGE_SUBSCRIPTION: '管理订阅',
  SUBSCRIPTION_ACTIVE: '积极的',
  SUBSCRIPTION_IN_ACTIVE: '不活跃',
  SUBSCRIPTION_END: '结束日期',
  PRICING_PLANS: '定价计划',
  CURRENCY: '欧元',
  MONTH: '月',
  YEAR: '这',
  ENTERPRISE_TITLE: '保持联系',
  ENTERPRISE_NAME: '铂',
  ENTERPRISE_DESCRIPTION: '为企业客户打造',
  ENTERPRISE_FEATURE1: '无限单位',
  ENTERPRISE_FEATURE2: '单点登录',
  ENTERPRISE_FEATURE3: '高可用性 SLA',
  ENTERPRISE_FEATURE4: '定制集成',
  ENTERPRISE_FEATURE5: '入职支持和培训',
  ENTERPRISE_FEATURE6: '所有黄金级功能',
  EDIT_ITEM: '更新',
  UPDATE_CHECKLIST_ITEM: '更新清单项目',
  EDIT: '编辑',
  N0_CLIENTS_ACTIVITIES: '没有客户活动',
  CREATION_DATE: '创建日期',
  ACTIVITY_ACTION: '行动',
  ACTIVITY_MODULE: '模块',
  CLIENT_NAME: '姓名',
  CLIENT_LABEL: '客户',
  CLIENT_ACTIVITY_SEARCH_PLACEHOLDER: '选择客户',
  CLIENT_ACTIVITY_TITLE: '客户活动',
  MULTI_MEDIA: '多媒体',
  CONDITION: '健康）状况',
  UPLOAD: '选择要上传的图像',
  BRONZE: '青铜',
  GOLD: '金子',
  ENTERPRISE: '企业',
  NONE: '没有任何',
  HISTORY: '历史',
  CHECKLIST_ITEM_DRILL_DOWN_DESCRIPTION:
    '以下是该设备所有检查的历史记录。单击每个日期可加载相应的报告。',
  REPORTS_TO_DATE: '迄今为止的报告',
  FLAGGED_REPORTS: '标记报告',
  ACTIVE_UNITS: '活跃单位',
  RECENT_REPORTS: '最近的报告',
  RECENT_UNITS: '最近添加的单位',
  INSPECTOR: '督察',
  DATE: '日期',
  REPORTS_COUNT: '报告',
  UNIT: '单元',
  VIEW_ALL: '查看全部',
  ISSUE_PRIORITY: '问题优先级',
  ISSUE_TITLE: '问题优先级',
  ISSUE_DESCRIPTION: '描述',
  ISSUE_ID: 'ID',
  ISSUE_REPORTED: '已报道',
  ISSUES: '已标记',
  ISSUE_GALLERY: '画廊',
  ISSUE_DETAILS: '问题详情',
  SHARING_REPORT: '分享',
  LOGGED_IN_FIRST: '您必须先登录',
  WELCOME_MESSAGE: '欢迎，',
  YOUR_STATISTICS_MESSAGE: '您今天的统计数据，',
  ISSUES_HEADER: '已标记',
  ISSUES_SUBHEADER: '管理检查中发现的问题',
  ISSUES_PRIORITY: '优先事项',
  UPPERCASE_TITLE: '标题',
  STATUS: '地位',
  UPPERCASE_UNIT: '单元',
  DESCRIPTION: '描述',
  IMAGES: '图片',
  REPORTED: '报道',
  DEACTIVATED: '已停用',
  DEACTIVATE: '停用',
  CO_SIGN: '联署',
  CO_SIGN_HERE: '在这里共同签名',
  SIGN_MODAL_TITLE: '共同签署报告',
  SIGN_MODAL_DESCRIPTION: '使用此对话窗口共同签署检查报告。',
  NAME_HOLDER: '添加姓名',
  NAME_REQUIRED: '名字的字段是必需的',
  SIGNATURE_IMAGE_URL: '签名',
  SIGNATURE_REQUIRED: '签名字段为必填项',
  SIGN_BUTTON: '提交',
  UPDATE_PASSWORD_INFO: '您想更新密码吗？',
  UPDATE_PASSWORD_LINK: '更新密码',
  UPDATE_USER_PASSWORD: '更改密码',
  UPDATE_USER_PASSWORD_CARD_TITLE: '用户信息',
  OLD_PASSWORD_LABEL: '当前密码',
  SAVE_USER_PASSWORD: '节省',
  OLD_PASSWORD_PLACEHOLDER: '当前密码...',
  COPY_REPORT_TOKEN: '复制报告令牌',
  QR_CODE: '二维码',
  QR_CODE_MODAL_TITLE: '检验链接二维码',
  DOWNLOAD: '下载',
  DOWNLOADED: '下载成功',
  DEVELOPMENT: '发展',
  UNIT_FORM_DESCRIPTION_PLACEHOLDER: '在此输入描述。',
  UNIT_FORM_TITLE_PLACEHOLDER: '在此输入标题。',
  UNIT_FORM_DESCRIPTION_CAPTION:
    '最终用户在启动报告时将看到此信息，因此请确保此描述可以帮助他们以最适合您业务的方式创建报告。',
  CREATE_USER_NAME_PLACEHOLDER: '在此输入姓名',
  CREATE_USER_EMAIL_PLACEHOLDER: '在此输入电子邮件',
  SAVE: '节省',
  CREATE: '创造',
  UPLOAD_CLIENT_LOGO: '标识',
  COPY: '复制',
  ROLE: '角色',
  ROLE_LABEL: '选择用户角色',
  ROLE_client: '客户管理',
  ROLE_client_manager: '客户经理',
  ROLE_client_contributor: '客户贡献者',
  USERS_LIST_EDIT_USER_BUTTON_TOOLTIP: '更新用户',
  USERS_LIST_UPDATE_USER_DIALOG_TITLE: '更新用户信息',
  USERS_LIST_UPDATE_USER_DIALOG_CAPTION: '更新用户',
  USERS_LIST_UPDATE_USER_DIALOG_CREATE_BUTTON: '更新用户',
  WEBHOOK_SCREEN_LIST_TITLE: '网络钩子',
  WEBHOOK_SCREEN_LIST_CAPTION: '在这里管理您的 webhook',
  WEBHOOK_SCREEN_LIST_CREATE_BUTTON: '添加网络钩子',
  REPORT_CREATED: '报告',
  ISSUE_CREATED: '问题',
  COUNTER_SIGNATURE_CREATED: '副署名',
  TYPE: '类型',
  URL: '网址',
  MODIFIED: '修改的',
  NO_WEBHOOKS_ADDED: '尚未添加 webhook。',
  ACTION: '行动',
  EDIT_WEBHOOK: '编辑',
  WEBHOOK_DETAILS: '细节',
  WEBHOOK_SCREEN_SAVE_TITLE: '添加网络钩子',
  WEBHOOK_SCREEN_SAVE_CAPTION:
    '选择您想要收到通知的事件类型，然后添加 Webhook 的 URL。',
  HOOK_TYPE_REQUIRED: '类型字段为必填项',
  HOOK_URL_REQUIRED: 'URL 字段为必填项',
  ADD_WEBHOOK: '添加网络钩子',
  WEBHOOK_TYPE_LABEL: '类型',
  WEBHOOK_TYPE_PLACEHOLDER: '类型',
  WEBHOOK_URL_PLACEHOLDER: '网址',
  WEBHOOK_URL_LABEL: '网址',
  WEBHOOK_SCREEN_DETAILS_TITLE: '网络钩子',
  WEBHOOK_SCREEN_DETAILS_CAPTION: '组织/Webhooks',
  HOOK_DATE: '日期',
  WEBHOOK_RETRY: '重试',
  SUCCESS: '成功',
  FAILED: '失败的',
  REQUEST_DETAIL_MODAL_HEADER: '索取详情',
  DETAILS: '细节',
  RESPONSE: '回复',
  BODY: '身体',
  ACTIONS: '行动',
  INVALID_MAGIC_LINK: '魔法链接可能无效或已过期',
  URL_VALIDATION: '请输入有效的网址',
  UNIT_FORM_REDIRECT_URL_PLACEHOLDER: '在此处输入检查重定向 URL。',
  UNIT_FORM_REDIRECT_URL_LABEL: '在此处输入检查重定向 URL。',
  ID_NAME: '身份证件名称',
  CREATED_AT: '已创建',
  OPEN: '打开',
  NEW_CHECKLIST_TITLE: '新清单',
  UNTITLED_CHECKLIST: '无标题清单',
  UNTITLED_CHECKLIST_FROM_TEMPLATE: '无标题清单（来自模板）',
  CREATE_CHECKLIST: '创建新清单',
  UPDATE_CHECKLIST: '更新清单',
  SAVE_CHECKLIST_SUBTITLE: '保存清单进度表',
  CHECKLISTS_TITLE: '清单',
  MANAGE_TEMPLATE: '管理',
  COPY_INSPECTION: '复制链接',
  DUPLICATE: '复制',
  DUPLICATED: '复制成功',
  NEW_UNIT_TITLE: '新单位',
  UPDATE_UNIT: '更新单位',
  CREATE_UNIT: '创建新单位',
  CREATE_UNIT_SUBTITLE: '单位是每次检查的目标，例如资产或位置',
  SAVE_UNIT_SUBTITLE: '保存单位',
  NEW_BLANK_TEMPLATE: '新的空白模板',
  TEMPLATE_PAGE_TITLE: '模板',
  PLATFORM_TEMPLATE_PAGE_TITLE: '平台模板',
  CLIENT_TEMPLATE_PAGE_TITLE: '模板库',
  UPDATE_TEMPLATE: '更新模板',
  SAVE_TEMPLATE_SUBTITLE: '更改模板的名称和图像',
  CREATE_TEMPLATE: '创建模板',
  SELECT_TEMPLATE: '选择模板',
  TEMPLATE_BUILDER: '模板生成器',
  MANAGE_CHECKLIST_ITEMS: '编辑清单项目',
  CREATE_BLANK: '创建空白',
  CHECKLIST_DELETE_ALERT_TITLE: '您确定要删除此清单吗？',
  CHECKLIST: '清单',
  CREATE_CATEGORY: '创建类别',
  UPDATE_CATEGORY: '更新类别',
  PEOPLE: '人们',
  SELECT: '选择....',
  LANGUAGE_pt: '葡萄牙语',
  LANGUAGE_en: '英语',
  LANGUAGE_fr: '法语',
  LANGUAGE_de: '德语',
  LANGUAGE_zh: '中国人',
  LANGUAGE_es: '西班牙语',
  LANGUAGE_DEFAULT: '默認',
  Dashboard: '仪表板',
  Issues: '问题',
  Organisation: '公司',
  USERS: '用户',
  Developer: '开发商',
  Webhooks: '网络钩子',
  Subscription: '订阅',
  MANAGE_UNITS: '管理您的单位',
  ID: 'ID',
  LAST_REPORT_DATE: '最新报告',
  DELETE_WARNING: '删除后将无法恢复',
  DELETE_CLIENT_WARNING: '您确实要删除该客户端吗',
  CREATE_CLIENT: '创建新客户',
  CREATE_CLIENT_DESCRIPTION: '使用此对话窗口添加新客户端',
  EMAIL: '电子邮件',
  IMAGE: '图像',
  INVALID_FILE_TYPE: '您无法上传这些文件类型',
  CLIENT_PASSWORD_INFO: '使用此密码登录',
  CREATE_CLIENT_BUTTON: '创建客户端',
  SIGN_OUT: '登出',
  DEVELOPER_DOCUMENTATION: '开发者文档',
  OF: '的',
  PAGINATION_INFO: '每页行数',
  CANCEL: '取消',
  Client: '分配给',
  Client_here: '未分配',
  New_Category: '新类别',
  EDIT_CHECKLIST_ITEM_Title: '问题',
  CREATE_INSTRUCTION: '添加说明',
  UPDATE_INSTRUCTION: '编辑说明',
  SAVE_INSTRUCTION_SUBTITLE:
    '说明图片和描述将显示在问题旁边，以帮助用户提供答案',
  ADD_ITEM: '+ 添加',
  TYPE_OF_RESPONSE: '响应类型',
  Image: '图片',
  EDIT_Instructions: '编辑说明',
  ASSIGN_OWNER: '分配所有者',
  ASSIGN_OWNER_TO: '将所有者分配给',
  REMOVE_OWNER: '删除所有者',
  OWNER_REMOVED: '所有者已删除',
  ASSIGNED_OWNER: '已分配所有者',
  SHARE_CHECK: '分享检查',
  SHARE_CHECK_DESCRIPTION: '使用下面的表单与检查人员共享。',
  TEMPLATE_BUILDER_BREADCRUMB: '模板',
  SEND_VIA: '发送方式',
  To: '收件人',
  Subject: '主题',
  EMAIL_TOKEN_DESCRIPTION: '描述',
  Share_Inspection: '共享检查',
  Open_Modal: '新检查',
  CHECKFIRST: 'Checkfirst',
  Select_unit: '选择单位',
  SELECT_CHECKLIST: '选择清单',
  Checklists: '清单',
  Templates: '模板',
  Email: '电子邮件',
  Add: '添加',
  Back: '返回',
  Owner: '所有者',
  Description: '描述',
  Created: '创建时间',
  UPGRADE: '升级',
  READY_FOR_AN_UPGRADE: '准备升级？',
  UPGRADE_ACCOUNT_TO_ACCESS_THEM:
    '由于您的计划使用限制，这些报告已被锁定。请' +
    '\n' +
    '升级您的账户以访问它们。',
  ADD_LOGIC: '添加逻辑',
  EDIT_CHECKLIST_ITEM: '说明',
  VERIFY_WITH_AI: '使用AI进行验证',
  ADD_INSTRUCTIONS: '添加说明',
  ADD_IMAGE: '添加图片',
  CHECKLIST_BUILDER: {
    TITLE_PLACEHOLDER: '标题',
    DESCRIPTION_PLACEHOLDER: '添加描述（可选）',
    ADD_CRITERIA_BUTTON_LABEL: '添加条件',
    ADD_SECTION_BUTTON_LABEL: '添加部分',
    SAVED_MESSAGE: '保存成功',
    BACK_BUTTON: '返回',
    NEW_CHECK_BUTTON: '新检查',
    PREVIEW_BUTTON: '预览模式',
    USE_TEMPLATE_BUTTON: '使用模板',
    FLOATING_TOOLBAR: {
      DUPLICATE: '复制部分',
      DELETE: '删除部分',
      ADD_CRITERIA: '添加条件',
      ADD_SECTION: '添加部分'
    },
    SECTION: {
      TITLE_PLACEHOLDER: '在此写下章节标题',
      DELETE_CONFIRMATION_MESSAGE: '这也将删除此部分中的所有项目。',
      DELETE_TOOLTIP: '删除',
      ERRORS: {
        REQUIRED: '标题字段是必需的。',
        DUPLICATE: '标题不得为以下值之一：{{sections}}'
      }
    },
    CRITERIA: {
      TITLE_PLACEHOLDER: '添加条件',
      TYPE_PLACEHOLDER: '响应类型',
      DELETE_CONFIRMATION_MESSAGE: '您确定要删除此条件吗？',
      REQUIRED_LABEL: '必需的',
      CHANGE_TYPE_CONFIRMATION_MESSAGE:
        '该条件包含逻辑和嵌套条件。 如果您更改条件类型，该信息将会丢失。 您想更改条件类型吗？',
      ERRORS: {
        REQUIRED: '标题字段是必需的。',
        MIN: '请输入至少 2 个选项。',
        MAX: '请输入少于 100 个选项。'
      }
    },
    LOGIC: {
      DAMAGED: '损坏的',
      POOR: '贫穷的',
      FAIR: '公平的',
      GOOD: '好的',
      GREAT: '伟大的',
      IF: '如果答案',
      IF_IMAGE: '如果是图像',
      THEN: '然后',
      YES: '是',
      NO: '否',
      NA: '不适用',
      IS: '是',
      IS_NOT: '不是',
      IS_EMPTY: '为空',
      IS_NOT_EMPTY: '非空',
      ADD_CRITERIA: '显示条件',
      FLAG_REPORT: '标记报告',
      RAISE_ISSUE: '提出问题',
      EQUALS: '等于 (=)',
      DOES_NOT_EQUAL: '不等于 (≠)',
      GREATER_THAN: '大于 (>)',
      LESS_THAN: '小于 (<)',
      GREATER_THAN_OR_EQUAL_TO: '大于或等于 (≥)',
      LESS_THAN_OR_EQUAL_TO: '小于或等于 (≤)',
      BETWEEN: '之间',
      NOT_BETWEEN: '不在之间',
      CONTAINS: '包含',
      DOES_NOT_CONTAIN: '不包含',
      STARTS_WITH: '以。。开始',
      ACTION_PLACEHOLDER: '选择动作',
      YES_NO_PLACEHOLDER: '选择多个值',
      DELETE_CONFIRMATION_MESSAGE: '您确定要删除这个逻辑吗？'
    },
    INSTRUCTIONS: {
      PLACEHOLDER: '文字...',
      LABEL: '说明',
      IMAGE_LABEL: '图片'
    }
  },
  SaveBasicInformation: {
    ERRORS: {
      TITLE_REQUIRED: ' 设备名称字段是必填项。',
      CLIENT_FIELD_REQUIRED: '客户字段是必填项。'
    }
  },
  SHARE_INSPECTION_MODAL: {
    STEP_1: {
      TITLE: '选择清单',
      SUBTITLE: '从下面的清单列表中选择要检查的内容。',
      NAME: '名称',
      CREATED: '创建时间',
      NEW_CHECKLIST: '新清单'
    },
    STEP_2: {
      TITLE: '选择单位',
      SUBTITLE: '选择要检查的单位。',
      NAME: '名称',
      OWNER: '所有者',
      CREATED: '创建时间',
      NEW_UNIT: '新单位'
    },
    STEP_3: {
      TITLE: '成功！',
      SUBTITLE: '检查{{id}}已成功创建。使用下面的表单与检查者分享它。',
      SEND_VIA: '通过发送',
      EMAIL: '电子邮件',
      LINK: '链接',
      QR: '二维码',
      COPY: '复制',
      CANCEL: '取消'
    }
  },
  CHECKS_PAGE: {
    BUTTONS: {
      PREVIEW: '预览',
      SHARE: '分享'
    },
    HEADERS: {
      ID: 'ID',
      CHECKLIST: '检查清单',
      UNIT: '单位',
      SCHEDULED: '已安排',
      DUE: '到期',
      DUE_DATE: '截止日期',
      ASSIGNED: '已分配',
      ASSIGNED_TO: '分配给',
      STATUS: '状态'
    },
    STATUS: {
      OPEN: '打开',
      STARTED: '已开始',
      COMPLETED: '已完成',
      SCHEDULED: '已安排',
      CLOSED: '已关闭'
    }
  },
  LOGO_ALT: 'Logo',
  CHECKLIST_ITEM_TYPE: {
    'Yes/No': '是/否',
    MEDIA: '媒体',
    TEXT: '文本',
    NUMBER: '数字',
    RADIO_BUTTON: '单选按钮',
    CHECK_BOX: '复选框',
    MULTIPLE_CHOICE: '多项选择',
    RESPONSE_TYPES: '响应类型',
    CUSTOM_OPTIONS: '自定义选项',
    MULTI_MEDIA: '媒体',
    AI: '人工智能',
    CONDITION: '条件',
    MULTIPLE_CHOICE_OPTIONS: {
      YES: '是',
      NO: '否',
      NA: 'N/A',
      GOOD: '良好',
      FAIR: '一般',
      POOR: '差',
      CONFORM: '符合',
      NOT_CONFORM: '不符合',
      PENDING: '待定',
      PLACEHOLDER: '选项标题'
    },
    MULTIPLE_CHOICE_MODAL: {
      TITLE: '多项选择选项',
      SUBTITLE: '例如：是、否、N/A',
      OPTION: '选项',
      DELETE: '删除',
      ADD_OPTION_BUTTON: '添加选项（或按Enter键）',
      SAVE_BUTTON: '保存',
      CANCEL_BUTTON: '取消',
      MULTIPLE_SELECTION_TOGGLE: '多项选择',
      SCORING_TOGGLE: '评分',
      SCORE: '分数'
    }
  },
  REPORTS_PAGE: {
    BUTTONS: {
      PREVIEW: '预览',
      SHARE: '分享'
    },
    HEADERS: {
      IMAGE_URL: '图片URL',
      RULE: '规则',
      IMAGE_CAPTION: '图片说明',
      WORDS_USED_TO_CHECK: '用于检查的词语',
      DECISION: '决定',
      VERIFIED: '已验证',
      VERIFICATION_PROMPT: '验证提示',
      ASSIGNED: '已分配',
      ASSIGNED_TO: '分配给',
      STATUS: '状态'
    },
    STATUS: {
      OPEN: '开放',
      STARTED: '已开始',
      COMPLETED: '已完成',
      SCHEDULED: '已计划',
      CLOSED: '已关闭'
    }
  },

  SHOW_MORE: '▼ 显示更多',
  SHOW_LESS: '▲ 显示更少',
  CONDITION_RESPONSES: {
    DAMAGED: '损坏的',
    POOR: '贫穷的',
    FAIR: '公平的',
    GOOD: '好的',
    GREAT: '伟大的'
  },
  SHOW_DETAILS: '显示详情',
  CHECKLISTS: {
    NAME: '名称',
    CREATED_BY: '创建者',
    LAST_MODIFIED: '上次修改',
    NEW_CHECKLIST: '新清单'
  },
  NO_CHECKS_CREATED: '没有创建检查',
  NO_REPORTS_CREATED: '没有创建报告',
  NO_CHECKLIST_CREATED: '此单元没有添加检查清单'
};

export default znJSON;

import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import { SnackbarProvider } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import useAnalytics from 'src/hooks/useAnalytics';
import ErrorBoundary from './wrappers/ErrorBoundary';
import { Stores } from './stores';
import { StoresProvider } from './providers/store.provider';
import { I18nextProvider } from 'react-i18next';
import { universalI18n } from './i18n/i18n';

function App() {
  const [rootStore, setRootStore] = useState<Stores | null>(null);
  const content = useRoutes(router);
  const auth = useAuth();
  const { initializeFirebase } = useAnalytics();
  useEffect(() => {
    initializeFirebase();
  }, []);

  return (
    <ThemeProvider>
      {/* TODO: Move stripe to subscription screen and import it from env file */}
      <StoresProvider getRootStore={(store) => setRootStore(store)}>
        <I18nextProvider i18n={universalI18n}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <ErrorBoundary>
              <CssBaseline />
              {auth.isInitialized ? content : <AppInit />}
            </ErrorBoundary>
          </SnackbarProvider>
        </I18nextProvider>
      </StoresProvider>
    </ThemeProvider>
  );
}
export default App;

import { action, makeObservable, observable } from 'mobx';
import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut
} from 'src/axios/axiosUtils';
import { LoadingState } from 'src/axios/types';
import { Stores } from 'src/stores';
import { BaseBackendStore } from '../types';
import type {
  ChecklistsMetaResponse,
  CreateChecklistParams,
  FilterGetChecklistsParams,
  GetChecklistDto,
  GetChecklistParams,
  UpdateChecklistParams
} from './types';
import {
  addLogic,
  assignTemplateToChecklistURL,
  deleteLogic,
  unitChecklist,
  unitChecklistCategories,
  unitChecklistCategoryItems,
  unitChecklists,
  updateLogic
} from 'src/axios/requests';
import type { ChecklistItemCategory, ViewChecklistItem } from 'src/models/unit';
import { CategoryDto, Reorder } from '../unit/types';
import type { LogicDto } from 'src/models/logic';
import { ChecklistItemsStore } from '../checklistItems';

export class ChecklistStore extends BaseBackendStore {
  private checklistItemsStore: ChecklistItemsStore;

  @observable checklistsResponse: ChecklistsMetaResponse = {
    data: [],
    meta: {
      itemCount: 0
    }
  };

  @observable checklistsState: LoadingState = LoadingState.IDLE;

  @observable checklist: GetChecklistDto;

  @observable checklistState: LoadingState = LoadingState.IDLE;

  @observable saveChecklistState: LoadingState = LoadingState.IDLE;

  @observable assignTemplateToChecklistState: LoadingState = LoadingState.IDLE;

  constructor(public rootStore: Stores) {
    super();
    makeObservable(this);
    this.checklistItemsStore = new ChecklistItemsStore(rootStore);
  }

  async getUnitChecklists({
    page,
    sortBy,
    sortOrder,
    take,
    unitId
  }: FilterGetChecklistsParams) {
    try {
      const params = new URLSearchParams({
        take: `${take}`,
        page: `${page + 1}`,
        'sort-by': sortBy,
        'sort-order': sortOrder
      }).toString();
      this.checklistsState = LoadingState.LOADING;
      this.checklistsResponse = await httpGet(
        `${unitChecklists(unitId)}?${params}`,
        false
      );
      this.checklistsState = LoadingState.DONE;
    } catch {
      this.checklistsState = LoadingState.FAILED;
    }
  }

  async getUnitChecklist({ checklistId }: GetChecklistParams) {
    try {
      this.checklistState = LoadingState.LOADING;
      this.checklist = await httpGet(unitChecklist(checklistId), false, {
        headers: {
          'request-version': '2'
        }
      });
      this.checklistItemsStore.setChecklistItemCategories(
        this.checklist.checklistItemsCategories
      );
      this.checklistState = LoadingState.DONE;
    } catch {
      this.checklistState = LoadingState.FAILED;
    }
  }

  async createUnitChecklist({
    title,
    description,
    imageURL,
    unitId,
    initDefaultSection
  }: CreateChecklistParams): Promise<number> {
    try {
      this.saveChecklistState = LoadingState.LOADING;
      const res = await httpPost(unitChecklists(unitId), {
        title,
        description,
        imageURL,
        initDefaultSection
      });
      this.saveChecklistState = LoadingState.DONE;
      return res?.id;
    } catch (err) {
      this.saveChecklistState = LoadingState.FAILED;
      throw err;
    }
  }

  async copyUnitChecklist(
    title: string,
    unitId: number,
    copyOf: number
  ): Promise<number> {
    try {
      this.saveChecklistState = LoadingState.LOADING;
      const res = await httpPost(`${unitChecklists(unitId)}/clone`, {
        title,
        copyOf
      });
      this.saveChecklistState = LoadingState.DONE;
      return res?.id;
    } catch (err) {
      this.saveChecklistState = LoadingState.FAILED;
      throw err;
    }
  }

  async assignTemplateToChecklist(checklistId: number, templateId: number) {
    try {
      this.assignTemplateToChecklistState = LoadingState.LOADING;
      await httpPost(assignTemplateToChecklistURL(checklistId, templateId), {});
      this.assignTemplateToChecklistState = LoadingState.DONE;
    } catch (error) {
      this.assignTemplateToChecklistState = LoadingState.FAILED;
      throw error;
    }
  }

  async updateUnitChecklist({
    title,
    description,
    imageURL,
    checklistId,
    unitId
  }: UpdateChecklistParams) {
    try {
      this.saveChecklistState = LoadingState.LOADING;
      await httpPut(`${unitChecklists(unitId)}/${checklistId}`, {
        title,
        description,
        imageURL
      });
      this.saveChecklistState = LoadingState.DONE;
    } catch {
      this.saveChecklistState = LoadingState.FAILED;
    }
  }

  /**
   *
   * @param checklistId
   * @param payload
   * @description
   *  - create category
   *  - insert it locally at the beginning of the list
   *  - reorder current categories
   */
  async addNewCategory(
    unitId: number,
    checklistId: number,
    payload: CategoryDto
  ) {
    const createdCategory: ChecklistItemCategory = await httpPost(
      unitChecklistCategories(unitId, checklistId),
      payload
    );
    this.checklist.checklistItemsCategories =
      this.checklistItemsStore.addNewCategory(createdCategory);
    return createdCategory;
  }

  async updateCategory(
    unitId: number,
    checklistId: number,
    categoryId: number,
    payload: CategoryDto
  ) {
    await httpPut(
      `${unitChecklistCategories(unitId, checklistId)}/${categoryId}`,
      payload
    );
    this.checklist.checklistItemsCategories =
      this.checklistItemsStore.updateCategory(categoryId, payload);
  }

  async deleteCategory(
    unitId: number,
    checklistId: number,
    categoryId: number
  ) {
    await httpDelete(
      `${unitChecklistCategories(unitId, checklistId)}/${categoryId}`
    );
    this.checklist.checklistItemsCategories =
      this.checklistItemsStore.deleteCategory(categoryId);
  }

  async reorderCategories(
    unitId: number,
    checklistId: number,
    categories: ChecklistItemCategory[]
  ) {
    const fallbackCategoriesOrder = [
      ...this.checklist.checklistItemsCategories
    ];
    try {
      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.reorderCategories(categories);
      const ordered = this.checklist.checklistItemsCategories.map(
        ({ id, order }) => ({
          id,
          order
        })
      );
      await httpPatch(unitChecklistCategories(unitId, checklistId), ordered);
    } catch (error) {
      this.checklist.checklistItemsCategories = fallbackCategoriesOrder;
    }
  }

  /** Checklist items */
  @action
  async deleteChecklistItem(
    unitId: number,
    checklistId: number,
    categoryId: number,
    id: number,
    logicState?: LogicDto
  ) {
    this.checklist.checklistItemsCategories =
      this.checklistItemsStore.deleteChecklistItem(categoryId, id, logicState);
    await httpDelete(
      `${unitChecklistCategoryItems(unitId, checklistId, categoryId)}/${id}`
    );
  }

  @action
  async updateChecklistItem(
    unitId: number,
    checklistId: number,
    categoryId: number,
    id: number,
    checklistItem: ViewChecklistItem,
    checklistItemState?: ViewChecklistItem
  ) {
    const result = await httpPut(
      `${unitChecklistCategoryItems(unitId, checklistId, categoryId)}/${id}`,
      checklistItem
    );
    this.checklist.checklistItemsCategories =
      this.checklistItemsStore.updateChecklistItem(
        categoryId,
        id,
        result,
        checklistItemState
      );
    return result;
  }

  @action
  async addChecklistItem(
    unitId: number,
    checklistId: number,
    categoryId: number,
    item: ViewChecklistItem,
    logicState?: LogicDto
  ) {
    const { subLogics, ...itemWithoutSubLogics }: ViewChecklistItem = item;

    const { id, order }: ViewChecklistItem = await httpPost(
      unitChecklistCategoryItems(unitId, checklistId, categoryId),
      itemWithoutSubLogics,
      {
        headers: {
          logicId: logicState?.id.toString()
        }
      }
    );

    this.checklist.checklistItemsCategories =
      this.checklistItemsStore.addChecklistItem(
        categoryId,
        { ...item, id },
        logicState
      );
    return { id, order };
  }

  @action
  async duplicateChecklistItem(
    unitId: number,
    checklistId: number,
    categoryId: number,
    item: ViewChecklistItem,
    logicState?: LogicDto
  ) {
    const { subLogics, ...itemToAdd } = item;
    const result: ViewChecklistItem = await httpPost(
      unitChecklistCategoryItems(unitId, checklistId, categoryId),
      itemToAdd,
      {
        headers: {
          logicId: logicState?.id.toString()
        }
      }
    );

    this.checklist.checklistItemsCategories =
      this.checklistItemsStore.addChecklistItem(
        categoryId,
        { ...result },
        logicState
      );
    return result;
    // this.checklist.checklistItemsCategories = [...this.checklist.ch]
  }

  @action
  async reorderChecklistItems(
    unitId: number,
    checklistId: number,
    categoryId: number,
    checklistItems: Array<ViewChecklistItem>,
    logicState?: LogicDto
  ) {
    const categoryIndex = this.checklist.checklistItemsCategories.findIndex(
      (cat) => cat.id === categoryId
    );
    const fallbackChecklistItems = [
      ...this.checklist.checklistItemsCategories[categoryIndex].checklistItems
    ];
    try {
      this.checklist.checklistItemsCategories =
        this.checklistItemsStore.reorderChecklistItems(
          categoryId,
          checklistItems,
          logicState
        );

      const checklistItemsOrdered: Reorder[] = (
        logicState
          ? logicState.subChecklistItems
          : this.checklist.checklistItemsCategories[categoryIndex]
              .checklistItems
      ).map(({ order, id }) => ({ order, id }));
      await httpPatch(
        unitChecklistCategoryItems(unitId, checklistId, categoryId),
        checklistItemsOrdered
      );
    } catch (error) {
      this.checklist.checklistItemsCategories[categoryIndex] = {
        ...this.checklist.checklistItemsCategories[categoryIndex],
        checklistItems: fallbackChecklistItems
      };
    }
  }

  /**Logics */
  @action
  async addLogic(
    checklistItemState: ViewChecklistItem,
    item: LogicDto,
    unitId: number
  ) {
    const { id }: LogicDto = await httpPost(
      addLogic(unitId, checklistItemState.id),
      item
    );
    this.checklistItemsStore.addLogic(checklistItemState, {
      ...item,
      id
    });
  }

  @action
  async updateLogic(logicState: LogicDto, newLogic: LogicDto, unitId: number) {
    console.log('adascacsa', logicState);
    console.log('adascacsanewLogic', newLogic);

    await httpPut(updateLogic(unitId, logicState.id), newLogic);
    this.checklistItemsStore.updateLogic(logicState, newLogic);
  }

  @action
  async deleteLogic(
    checklistItemState: ViewChecklistItem,
    logicId: number,
    unitId: number
  ) {
    await httpDelete(deleteLogic(unitId, logicId));
    this.checklistItemsStore.deleteLogic(checklistItemState, logicId);
  }
}

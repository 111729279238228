const deJSON = {
  NO: 'Nein',
  YES: 'Ja',
  NOT_SURE: 'Nicht sicher',
  NA: 'N/A',
  REPORT_DETAILS_HEADER_TITLE: 'Berichtsdetails',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'PDF Herunterladen',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Laden Sie XLSX herunter',
  REPORT_DETAILS_NO_ANSWER: 'Keine Antwort',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Rechenschaftspflicht',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'In diesem Abschnitt wird angezeigt, wer den Bericht erstellt hat',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Überprüfung',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Alle Daten mit Checkfirst verifiziert',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Einheit',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspektion',
  ISSUES_HEADER: 'Gekennzeichnet',
  ISSUES_SUBHEADER: 'Verwalten Sie bei der Inspektion festgestellte Probleme',
  ISSUES_PRIORITY: 'Priorität',
  ISSUES_REPORTED: 'Es wurden Probleme gemeldet',
  COMPLETED_AT: 'Abgeschlossen um',
  BY: 'von',
  ON: 'An',
  CRITERIA_CHECKED: 'Kriterien wurden quer geprüft',
  SECTIONS: 'Abschnitte',
  NO_FLAGGED_ITEMS: 'Es wurden keine Artikel markiert',
  FLAGGED_ITEMS: 'Artikel wurden markiert',
  CHECKER: 'Checker berichtete, dass dies der Fall sei',
  NO_ISSUES_RAISED: 'Der Bericht weist keine Probleme auf',
  ISSUE_RAISED: 'Problem angesprochen',
  REMARK: 'Anmerkung',
  LOCATION: 'Standort',
  UPPERCASE_TITLE: 'TITEL',
  STATUS: 'STATUS',
  UPPERCASE_UNIT: 'EINHEIT',
  UNIT: 'Einheit',
  DEVICE_DETAILS: 'Gerätedetails',
  DATE: 'Datum',
  NAME: 'Name',
  SIGNATURE: 'Unterschrift',
  COUNTER_SIGNATURE_CREATED: 'Gegenzeichnung',
  CO_SIGNED: 'Mitunterzeichnet',
  CO_SIGNATURE: 'Mitunterschrift',
  IP_ADDRESS: 'IP Adresse',
  SIGN: 'Zeichen',
  DOWNLOAD: 'Herunterladen',
  DOWNLOADED: 'Erfolgreich heruntergeladen',
  CHECKLIST: 'Checkliste',
  IMAGES: 'BILDER',
  REPORTED: 'BERICHTET',
  ID: 'AUSWEIS',
  HIGH: 'Hoch',
  MEDIUM: 'Mittel',
  LOW: 'Niedrig',
  SAVED: 'Erfolgreich gespeichert',
  REPORT_SUMMARY: 'Zusammenfassung des Berichts',
  CRITERIA: 'Kriterien',
  RESPONSE: 'Antwort',
  ISSUE_ID: 'Problem ID',
  ISSUES_TABLE: 'Problemtabelle',
  SECTION: 'Abschnitt',
  CONDITION_RESPONSES: {
    DAMAGED: 'Beschädigt',
    POOR: 'Arm',
    FAIR: 'Gerecht',
    GOOD: 'Gut',
    GREAT: 'Großartig'
  },
  CUSTOMISE: 'Anpassen',
  REPORT_CONFIG: {
    SHOW_SUMMARY: 'Berichtszusammenfassung',
    SHOW_ISSUES: 'Gemeldete Probleme',
    SHOW_FLAGGED: 'Markierte Elemente',
    SHOW_SCORING: 'Bewertung',
    SHOW_CO_SIGNATURE: 'Mitunterschrift'
  },
  SCORING_SUMMARY: {
    SCORING: 'Bewertung',
    ITEM_TYPES: {
      SINGLE_CHOICE: 'Einzelne Auswahl',
      'Yes/No': 'Ja/Nein',
      MULTI_MEDIA: 'Multimedia',
      CONDITION: 'Bedingung',
      TEXT: 'Text',
      MULTIPLE_CHOICE: 'Mehrfachauswahl - mehrere Antworten'
    },
    TABLE: {
      NUMBER: 'Nr.',
      SECTION: 'Abschnitt',
      OBTAINED: 'Erhalten',
      POSSIBLE: 'Möglich',
      SCORE: 'Punktzahl',
      TOTAL: 'Gesamt'
    }
  },
  FLAGGED_ITEMS_ACCORDION_SUMMARY: 'Markierte Elemente',
  SECTION_NAME: 'Abschnitt'
};

export default deJSON;

const znJSON = {
  NO: '不',
  YES: '是的',
  NOT_SURE: '没有把握',
  NA: '不适用',
  REPORT_DETAILS_HEADER_TITLE: '报告详情',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: '下载PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: '下载XLSX',
  REPORT_DETAILS_NO_ANSWER: '没有答案',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: '问责制',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE: '此部分显示谁执行了报告',
  REPORT_VERIFICATION_VERIFICATION_TITLE: '确认',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE: ' 所有数据均通过 Checkfirst 验证',
  REPORT_HEADER_UNIT_ID_PREFIX: ' 单元',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' 检查',
  ISSUES_HEADER: '已标记',
  ISSUES_SUBHEADER: '管理检查中发现的问题',
  ISSUES_PRIORITY: '优先事项',
  ISSUES_REPORTED: '已报告问题',
  COMPLETED_AT: '完成于',
  BY: '经过',
  ON: '在',
  CRITERIA_CHECKED: '已检查标准',
  SECTIONS: '部分',
  NO_FLAGGED_ITEMS: '没有标记任何项目',
  FLAGGED_ITEMS: '项目已被标记',
  CHECKER: '检查员报告说他们是',
  NO_ISSUES_RAISED: '报告没有问题',
  ISSUE_RAISED: '提出的问题',
  REMARK: '评论',
  LOCATION: '地点',
  UPPERCASE_TITLE: '标题',
  STATUS: '地位',
  UPPERCASE_UNIT: '单元',
  UNIT: '单元',
  DEVICE_DETAILS: '设备详细信息',
  DATE: '日期',
  NAME: '姓名',
  SIGNATURE: '签名',
  COUNTER_SIGNATURE_CREATED: '副署名',
  CO_SIGNED: '联署',
  CO_SIGNATURE: '共同签名',
  IP_ADDRESS: 'IP地址',
  SIGN: '符号',
  DOWNLOAD: '下载',
  DOWNLOADED: '下载成功',
  CHECKLIST: '清单',
  IMAGES: '图片',
  REPORTED: '报道',
  ID: 'ID',
  HIGH: '高的',
  MEDIUM: '中等的',
  LOW: '低的',
  SAVED: '保存成功',
  REPORT_SUMMARY: '报告摘要',
  CRITERIA: '标准',
  RESPONSE: '回复',
  ISSUE_ID: '问题ID',
  ISSUES_TABLE: '问题表',
  SECTION: '部分',
  CONDITION_RESPONSES: {
    DAMAGED: '损坏的',
    POOR: '贫穷的',
    FAIR: '公平的',
    GOOD: '好的',
    GREAT: '伟大的'
  },
  CUSTOMISE: '自定义',
  REPORT_CONFIG: {
    SHOW_SUMMARY: '报告摘要',
    SHOW_ISSUES: '提出的问题',
    SHOW_FLAGGED: '已标记项目',
    SHOW_SCORING: '评分',
    SHOW_CO_SIGNATURE: '共同签名'
  },
  SCORING_SUMMARY: {
    SCORING: '评分',
    ITEM_TYPES: {
      SINGLE_CHOICE: '单选',
      'Yes/No': '是/否',
      MULTI_MEDIA: '多媒体',
      CONDITION: '条件',
      TEXT: '文本',
      MULTIPLE_CHOICE: '多选 - 多项选择'
    },
    TABLE: {
      NUMBER: '编号',
      SECTION: '部分',
      OBTAINED: '获得',
      POSSIBLE: '可能',
      SCORE: '得分',
      TOTAL: '总计'
    }
  },
  FLAGGED_ITEMS_ACCORDION_SUMMARY: '已标记项目',
  SECTION_NAME: '部分'
};

export default znJSON;

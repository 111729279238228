import { Box } from '@mui/material';
import { ChecklistItemType, ViewChecklistItem } from 'src/models/unit';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import LinearScaleOutlinedIcon from '@mui/icons-material/LinearScaleOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import ContrastIcon from '@mui/icons-material/Contrast';
import { FeatureStatus } from 'src/models/feature.toggle';
import { ReportChecklistItem } from 'src/models/report';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { MediaBox } from '../styled';

export const isDisabledMandatoryControl = (item: ViewChecklistItem) => {
  if (isCheckBox(item)) {
    return true;
  }
  return false;
};

export const getChecklistItemAllowedTypes = (type: ChecklistItemType) => {
  const showCheckAndRadio =
    process.env.REACT_APP_CHECK_RADIO == FeatureStatus.ENABLED;

  let excludedTyped = [
    ChecklistItemType.MEDIA,
    ChecklistItemType.MULTIPLE_CHOICE //will be handled separately
  ];

  if (!showCheckAndRadio) {
    excludedTyped = excludedTyped.concat([
      ChecklistItemType.RADIO_BUTTON,
      ChecklistItemType.CHECK_BOX
    ]);
  }

  return Object.values(ChecklistItemType).filter(
    (t) => !excludedTyped.includes(t)
  );
};

export const getType = (
  { type, t }: { type: ChecklistItemType; t: any },
  closed = false
) => {
  const translatedText = t(`CHECKLIST_ITEM_TYPE.${type}`);
  if (type === ChecklistItemType.TEXT) {
    return (
      <Box display="flex" alignItems="center">
        <ShortTextOutlinedIcon fontSize="small" />
        <Box padding={0.7} />
        {translatedText}
      </Box>
    );
  }
  if (type === ChecklistItemType.NUMBER) {
    return (
      <Box display="flex" alignItems="center">
        <NumbersOutlinedIcon fontSize="small" />
        <Box padding={0.7} />
        {translatedText}
      </Box>
    );
  }
  if (type === ChecklistItemType.CONDITION) {
    return (
      <Box display="flex" alignItems="center">
        <LinearScaleOutlinedIcon fontSize="small" />
        <Box padding={0.7} />
        {translatedText}
      </Box>
    );
  }
  if (type === ChecklistItemType.CHECK_BOX) {
    return (
      <Box display="flex" alignItems="center">
        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
        <Box padding={0.7} />
        {translatedText}
      </Box>
    );
  }
  if (type === ChecklistItemType.RADIO_BUTTON) {
    return (
      <Box display="flex" alignItems="center">
        <RadioButtonCheckedOutlinedIcon fontSize="small" />
        <Box padding={0.7} />
        {translatedText}
      </Box>
    );
  }
  if (type === ChecklistItemType.MULTI_MEDIA) {
    return (
      <>
        <Box display="flex" alignItems="center">
          <ImageOutlinedIcon fontSize="small" />
          <Box padding={0.7} />
          {translatedText}
        </Box>
        {!closed && (
          <MediaBox>
            <AutoAwesomeOutlinedIcon fontSize="small" />
            {t(`CHECKLIST_ITEM_TYPE.AI`)}
          </MediaBox>
        )}
      </>
    );
  }
  if (type === ChecklistItemType.YES_NO) {
    return (
      <Box display="flex" alignItems="center">
        <ContrastIcon fontSize="small" />
        <Box padding={0.7} />
        {translatedText}
      </Box>
    );
  }
  if (type) {
    return translatedText;
  }
  return null;
};

export const isShowOptionInput = (type: ChecklistItemType) => {
  return [ChecklistItemType.RADIO_BUTTON, ChecklistItemType.CHECK_BOX].includes(
    type
  );
};

export const numberOfWrappedItems = (flexContainer) => {
  const GAP_WIDTH = 4;
  if (!flexContainer) return;
  const containerWidth = flexContainer.clientWidth;
  let totalWidth = 0;
  let itemCount = 0;

  for (let i = 0; i < flexContainer.children.length; i++) {
    const element = flexContainer.children[i];
    const itemWidth = element.offsetWidth;
    if (totalWidth + itemWidth > containerWidth) {
      itemCount = flexContainer.children.length - i;
      break;
    } else {
      totalWidth += itemWidth + GAP_WIDTH;
    }
  }

  return itemCount;
};

export const toggleHiddenChips = (chipsRef: any, maxHeight: number) => {
  let nOfHiddenItem = 0;
  const validElements = chipsRef?.current?.filter((e) => e !== null);
  validElements?.forEach((e) => {
    if (e.offsetTop + e.offsetHeight > maxHeight + 8) {
      e.classList.remove('visible');
      nOfHiddenItem += 1;
    } else {
      e.classList.add('visible');
    }
  });
  return nOfHiddenItem;
};

export const isMultiChoice = (type: ChecklistItemType) =>
  type === ChecklistItemType.MULTIPLE_CHOICE ||
  type === ChecklistItemType.CHECK_BOX ||
  type === ChecklistItemType.RADIO_BUTTON;

export const isRadioButton = (
  checklistItem: ViewChecklistItem | ReportChecklistItem
) =>
  checklistItem.type == ChecklistItemType.RADIO_BUTTON ||
  (checklistItem.type == ChecklistItemType.MULTIPLE_CHOICE &&
    !checklistItem.allowMultipleOptions);

export const isCheckBox = (checklistItem: ViewChecklistItem) =>
  checklistItem.type == ChecklistItemType.CHECK_BOX ||
  (checklistItem.type == ChecklistItemType.MULTIPLE_CHOICE &&
    checklistItem.allowMultipleOptions);

export const mapChecklistItem = (
  item,
  order = undefined
): ViewChecklistItem => ({
  title: item.title ?? ' ',
  isDeleted: false,
  type: item.type as ChecklistItemType,
  mandatory: item.mandatory,
  checklistItemOptions: item.selectOptions ?? item.checklistItemOptions,
  order: order ?? item.order,
  instruction: item.instruction,
  imageURL: item.imageURL
});

import { Container, styled, Card } from '@mui/material';

export const FloatFormContainer = styled(Container)(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '70%'
}));

export const CheckFirstFormLogo = styled('img')(({ theme }) => ({
  width: theme.spacing(9),
  height: theme.spacing(9),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.colors.alpha.white[100],
  padding: theme.spacing(1),
  borderRadius: '50%',
  boxShadow: `1px 1px 5px 1px ${theme.colors.alpha.black[30]}`,
  position: 'relative'
}));

export const CheckFirstFormSuccessLogoWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginTop: theme.spacing(-6),
  backgroundColor: theme.colors.success.main,
  padding: theme.spacing(1),
  borderRadius: '50%',
  boxShadow: `1px 1px 5px 1px ${theme.colors.alpha.black[30]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute'
}));

export const CheckFirstFormFailureLogoWrapper = styled('div')(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  marginTop: theme.spacing(-6),
  backgroundColor: theme.colors.error.main,
  padding: theme.spacing(1),
  borderRadius: '50%',
  boxShadow: `1px 1px 5px 1px ${theme.colors.alpha.black[30]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute'
}));

export const FormCardSuccess = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5),
  paddingTop: theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}));

export const FormCardFailure = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5),
  paddingTop: theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}));

const frJSON = {
  NO: 'Non',
  YES: 'Oui',
  NOT_SURE: 'Pas certain',
  NA: 'N/A',
  REPORT_DETAILS_HEADER_TITLE: 'Détails du rapport',
  REPORT_DETAILS_HEADER_DOWNLOAD_PDF_BUTTON: 'Télécharger le PDF',
  REPORT_DETAILS_HEADER_DOWNLOAD_XLSX_BUTTON: 'Télécharger XLSX',
  REPORT_DETAILS_NO_ANSWER: 'Pas de réponse',
  REPORT_VERIFICATION_ACCOUNTABILITY_TITLE: 'Responsabilité',
  REPORT_VERIFICATION_ACCOUNTABILITY_SUBTITLE:
    'Cette section montre qui a réalisé le rapport',
  REPORT_VERIFICATION_VERIFICATION_TITLE: 'Vérification',
  REPORT_VERIFICATION_VERIFICATION_SUBTITLE:
    ' Toutes les données vérifiées avec Checkfirst',
  REPORT_HEADER_UNIT_ID_PREFIX: ' Unité',
  REPORT_HEADER_INSPECTION_ID_PREFIX: ' Inspection',
  ISSUES_HEADER: 'Marqué',
  ISSUES_SUBHEADER: 'Gérer les problèmes détectés lors de l’inspection',
  ISSUES_PRIORITY: 'Priorité',
  ISSUES_REPORTED: 'des problèmes ont été signalés',
  COMPLETED_AT: 'Terminé à',
  BY: 'par',
  ON: 'sur',
  CRITERIA_CHECKED: 'les critères ont été vérifiés à travers',
  SECTIONS: 'sections',
  NO_FLAGGED_ITEMS: "Aucun élément n'a été signalé",
  FLAGGED_ITEMS: 'les éléments ont été signalés partout',
  CHECKER: "le vérificateur a signalé qu'ils étaient",
  NO_ISSUES_RAISED: "Le rapport n'a aucun problème",
  ISSUE_RAISED: 'Problème soulevé',
  REMARK: 'Remarque',
  LOCATION: 'Emplacement',
  UPPERCASE_TITLE: 'TITRE',
  STATUS: 'STATUT',
  UPPERCASE_UNIT: 'UNITÉ',
  UNIT: 'Unité',
  DEVICE_DETAILS: "Détails de l'appareil",
  DATE: 'Date',
  NAME: 'Nom',
  SIGNATURE: 'Signature',
  COUNTER_SIGNATURE_CREATED: 'Contre signature',
  CO_SIGNED: 'Co-signé',
  CO_SIGNATURE: 'Co-signature',
  IP_ADDRESS: 'Adresse IP',
  SIGN: 'Signe',
  DOWNLOAD: 'Télécharger',
  DOWNLOADED: 'Téléchargé avec succès',
  CHECKLIST: 'Liste de contrôle',
  IMAGES: 'IMAGES',
  REPORTED: 'SIGNALÉ',
  ID: 'IDENTIFIANT',
  HIGH: 'Haut',
  MEDIUM: 'Moyen',
  LOW: 'Faible',
  SAVED: 'Enregistré avec succès',
  REPORT_SUMMARY: 'Résumé du rapport',
  CRITERIA: 'Critères',
  RESPONSE: 'Réponse',
  ISSUE_ID: 'ID du problème',
  ISSUES_TABLE: 'Tableau des enjeux',
  SECTION: 'Section',
  CONDITION_RESPONSES: {
    DAMAGED: 'Endommagé',
    POOR: 'Pauvre',
    FAIR: 'Équitable',
    GOOD: 'Bien',
    GREAT: 'Super'
  },
  CUSTOMISE: 'Personnaliser',
  REPORT_CONFIG: {
    SHOW_SUMMARY: 'Résumé du rapport',
    SHOW_ISSUES: 'Problèmes soulevés',
    SHOW_FLAGGED: 'Éléments signalés',
    SHOW_SCORING: 'Évaluation',
    SHOW_CO_SIGNATURE: 'Co-signature'
  },
  SCORING_SUMMARY: {
    SCORING: 'Évaluation',
    ITEM_TYPES: {
      SINGLE_CHOICE: 'Choix multiples',
      'Yes/No': 'Oui/Non',
      MULTI_MEDIA: 'Média',
      CONDITION: 'Condition',
      TEXT: 'Texte',
      MULTIPLE_CHOICE: 'Choix multiples - réponse multiple'
    },
    TABLE: {
      NUMBER: 'N°',
      SECTION: 'Section',
      OBTAINED: 'Obtenu',
      POSSIBLE: 'Possible',
      SCORE: 'Score',
      TOTAL: 'Total'
    }
  },
  FLAGGED_ITEMS_ACCORDION_SUMMARY: 'Éléments signalés',
  SECTION_NAME: 'Section'
};

export default frJSON;

import { Helmet } from 'react-helmet-async';
import { FC, useEffect, useState } from 'react';
import { Zoom } from '@mui/material';
import { DropResult } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { reorder } from 'src/helpers/Array';
import { observer } from 'mobx-react';
import BackendResourceWrapper from 'src/components/BackendResourceWraper';
import useAuth from 'src/hooks/useAuth';
import { PermissionKey, PermissionLevel } from 'src/models/user';
import { PageLayout } from 'src/content/dashboards/Templates/PageLayout';
import { useChecklistStore } from '../hooks/useChecklistStore';
import { LoadingState } from 'src/axios/types';
import { ChecklistItemsFormUI } from 'src/components/ChecklistItemsFormUI';

export const ChecklistItemsPage = observer(() => {
  const checklistBuilderStore = useChecklistStore();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const auth = useAuth();
  const hasAccess = auth.user.rolesPermissions.find(
    (permission) =>
      permission.level === PermissionLevel.ALL &&
      permission.permission === PermissionKey.ALLOW_TO_ACCESS_TEMPLATES
  );
  const [loadingState, setLoadingState] = useState(LoadingState.LOADING);

  useEffect(() => {
    checklistBuilderStore
      .getChecklist()
      .then(() => {
        setLoadingState(LoadingState.DONE);
      })
      .catch(() => setLoadingState(LoadingState.FAILED));
  }, []);

  useEffect(() => {
    return () => {
      checklistBuilderStore.clearChecklist();
    };
  }, []);

  const handleSaveSuccess = () => {
    enqueueSnackbar(t('SAVED'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const onCategoriesDragEnd = async ({ destination, source }: DropResult) => {
    const orderedCategories = reorder(
      checklistBuilderStore.checklist.checklistItemsCategories,
      source.index,
      destination.index
    ).map((cat, index) => {
      return {
        ...cat,
        order: index
      };
    });
    checklistBuilderStore.reorderCategories(orderedCategories);
    handleSaveSuccess();
  };

  return (
    <BackendResourceWrapper states={[loadingState]}>
      <PageLayout>
        <Helmet>
          <title>{checklistBuilderStore?.checklist?.title}</title>
        </Helmet>
        <ChecklistItemsFormUI
          categories={
            checklistBuilderStore?.checklist?.checklistItemsCategories || []
          }
          pageTitle={checklistBuilderStore?.checklist?.title}
          checklistDescription={checklistBuilderStore?.checklist?.description}
          checklistImageURL={checklistBuilderStore?.checklist?.imageURL || ''}
          isReadOnly={
            checklistBuilderStore?.checklist?.client || hasAccess ? false : true
          }
          ParentTitle={t('TEMPLATE_BUILDER_BREADCRUMB')}
          ParentTitleLink={'/checkfirst/dashboard/templates'}
          onCategoriesDragEnd={onCategoriesDragEnd}
        />
      </PageLayout>
    </BackendResourceWrapper>
  );
});

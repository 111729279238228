import { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import WelcomeScreen from 'src/content/pages/Auth/Register/welcome.screen';
import UpdateClientCompany from 'src/content/dashboards/Clients/Client-patch-company';
import LoginCover from 'src/content/pages/Auth/Login/Cover';
import { UsePermission } from '../PermissionWrapper';
import { PermissionKey } from 'src/models/user';
import { Language } from 'src/content/dashboards/reports/report/ReportTranslationDropdown/types';
import { useTranslation } from 'react-i18next';
import NotAuthenticatedTranslationLayout from '../NotAuthenticatedTranslationLayout';
import { FlaggedOption } from '../../models/unit';
import { useReports } from '../../hooks/useReports';
interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  const { hasPermission } = UsePermission();

  const allowToAccessDashboard = hasPermission(
    PermissionKey.ACCESS_DASHBOARD_WITHOUT_SUBSCRIPTION
  );
  // console.log('allowToAccessDashboardallowToAccessDashboard', allowToAccessDashboard, auth?.user)
  const [shouldSubscribe] = useState(
    auth.isAuthenticated && !allowToAccessDashboard
  );
  const { getReports } = useReports();

  useEffect(() => {
    if (hasPermission(PermissionKey.GET_REPORTS))
      getReports(`/reports`, null, FlaggedOption.all, 10);
  }, []);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return (
      <NotAuthenticatedTranslationLayout>
        <LoginCover />
      </NotAuthenticatedTranslationLayout>
    );
  }

  if (auth.isAuthenticated && shouldSubscribe && !auth.user?.client?.name) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <NotAuthenticatedTranslationLayout>
        <UpdateClientCompany />
      </NotAuthenticatedTranslationLayout>
    );
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
